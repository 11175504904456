import { cva } from "class-variance-authority";

export const buttonRootVariants = cva(
  [
    "inline-grid grid-flow-col place-items-center justify-center disabled:cursor-not-allowed",
  ],
  {
    variants: {
      theme: {
        light: "",
        dark: "",
      },
      isDanger: {
        true: "",
        false: "",
      },
      size: {
        medium: "grid-cols-[24px_auto_24px] grid-rows-[7px_auto_7px] text-h4",
        small: "grid-cols-[24px_auto_24px] grid-rows-[5px_auto_5px] text-h5",
        "x-small":
          "grid-cols-[16px_auto_16px] grid-rows-[4px_auto_4px] text-h6 leading-[18px]",
      },
      hasIcon: {
        true: "",
        false: "[grid-template-areas:'._._.''._content_.''._._.']",
      },
      iconPosition: {
        before: "",
        after: "",
      },
      variant: {
        primary: "rounded border border-solid",
        secondary: "rounded border border-solid",
        text: "grid-cols-[0_auto_0] grid-rows-[0_auto_0] px-0 underline",
      },
      isLoading: {
        true: "text-transparent hover:cursor-wait",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        isDanger: false,
        theme: "light",
        className: [
          "border-green-500 bg-green-500 text-white-100",
          "active:bg-green-900 active:text-white-100",
          "hover:bg-green-700 hover:text-white-100",
          "disabled:border-grey-200 disabled:bg-grey-200 disabled:text-grey-700",
        ],
      },
      {
        variant: "primary",
        isDanger: true,
        theme: "light",
        className: [
          "border-red-700 bg-red-700 text-white-100",
          "active:bg-red-900 active:text-white-100",
          "hover:bg-red-800 hover:text-white-100",
          "disabled:border-grey-200 disabled:bg-grey-200 disabled:text-grey-700",
        ],
      },
      {
        variant: "primary",
        isDanger: false,
        theme: "dark",
        className: [
          "border-white-100 bg-white-100 text-green-500",
          "active:bg-white-100 active:text-green-500",
          "hover:bg-grey-300 hover:text-green-500",
          "disabled:border-grey-300 disabled:bg-grey-300 disabled:text-grey-800",
        ],
      },

      {
        variant: "primary",
        isDanger: true,
        theme: "dark",
        className: [
          "border-red-500 bg-red-500 text-white-100",
          "active:bg-red-700 active:text-white-100",
          "hover:border-white-100 hover:bg-red-600 hover:text-white-100",
          "disabled:border-grey-300 disabled:bg-grey-300 disabled:text-grey-700",
        ],
      },
      {
        variant: "secondary",
        isDanger: false,
        theme: "light",
        className: [
          "border-green-500 bg-transparent text-green-500",
          "active:bg-opal-200 active:text-green-900",
          "hover:bg-positive-background hover:text-green-700",
          "disabled:border-grey-600 disabled:bg-transparent disabled:text-grey-700",
        ],
      },
      {
        variant: "secondary",
        isDanger: true,
        theme: "light",
        className: [
          "border-red-700 bg-transparent text-red-700",
          "active:bg-red-200 active:text-red-900",
          "hover:bg-red-100 hover:text-red-800",
          "disabled:border-grey-600 disabled:bg-transparent disabled:text-grey-700",
        ],
      },
      {
        variant: "secondary",
        isDanger: false,
        theme: "dark",
        className: [
          "bg-transparent text-white-100",
          "active:bg-transparent active:text-white-100",
          "hover:border-white-100 hover:bg-transparent hover:text-white-100",
          "disabled:border-grey-500 disabled:bg-transparent disabled:text-grey-500",
        ],
      },
      {
        variant: "secondary",
        isDanger: true,
        theme: "dark",
        className: [
          "bg-transparent text-red-700",
          "active:bg-red-200 active:text-red-900",
          "hover:bg-red-100 hover:text-red-800",
          "disabled:border-grey-500 disabled:bg-transparent disabled:text-grey-500",
        ],
      },

      {
        variant: "text",
        isDanger: false,
        theme: "light",
        className: [
          "bg-transparent text-green-500",
          "active:transparent active:text-green-900",
          "hover:bg-transparent hover:text-green-700",
          "disabled:bg-transparent disabled:text-grey-700",
        ],
      },
      {
        variant: "text",
        isDanger: true,
        theme: "light",
        className: [
          "bg-transparent text-red-700",
          "active:transparent active:text-red-900",
          "hover:bg-transparent hover:text-red-800",
          "disabled:bg-transparent disabled:text-grey-700",
        ],
      },
      {
        variant: "text",
        isDanger: false,
        theme: "dark",
        className: [
          "bg-transparent text-white-100",
          "active:transparent active:text-white-100",
          "hover:bg-transparent hover:text-grey-300",
          "disabled:bg-transparent disabled:text-grey-500",
        ],
      },
      {
        variant: "text",
        isDanger: true,
        theme: "dark",
        className: [
          "bg-transparent text-red-500",
          "active:transparent active:text-red-900",
          "hover:bg-transparent hover:text-red-800",
          "disabled:bg-transparent disabled:text-grey-500",
        ],
      },
      {
        size: "medium",
        hasIcon: true,
        className: "grid-cols-[24px_auto_8px_auto_24px]",
      },
      {
        size: "small",
        hasIcon: true,
        className: "grid-cols-[24px_auto_8px_auto_24px]",
      },
      {
        size: "x-small",
        hasIcon: true,
        className: "grid-cols-[16px_auto_8px_auto_16px]",
      },
      {
        variant: "text",
        size: "medium",
        hasIcon: true,
        className: "grid-cols-[0_auto_8px_auto_0]",
      },
      {
        variant: "text",
        size: "small",
        hasIcon: true,
        className: "grid-cols-[0_auto_8px_auto_0]",
      },
      {
        variant: "text",
        size: "x-small",
        hasIcon: true,
        className: "grid-cols-[0_auto_8px_auto_0]",
      },
      {
        iconPosition: "before",
        hasIcon: true,
        className:
          "[grid-template-areas:'._._._._.''._icon_._content_.''._._._._.']",
      },
      {
        iconPosition: "after",
        hasIcon: true,
        className:
          "[grid-template-areas:'._._._._.''._content_._icon_.''._._._._.']",
      },
      {
        variant: "primary",
        isLoading: true,
        className: "disabled:text-transparent",
      },
    ],
    defaultVariants: {
      size: "medium",
      theme: "light",
      variant: "primary",
      iconPosition: "before",
      hasIcon: false,
      isDanger: false,
      isLoading: false,
    },
  },
);

export const spinnerVariants = cva("col-span-full row-span-full", {
  variants: {
    theme: {
      light: "text-white-100",
      dark: "text-green-500",
    },
    size: {
      medium: "h-6",
      small: "h-4",
      "x-small": "h-4",
    },
  },
});
