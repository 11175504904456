import { useJsApiLoader } from "@react-google-maps/api";
import "jquery/src/jquery";
import "leaflet/dist/leaflet.css";
import log from "loglevel";
import { useEffect } from "react";
import Div100vh from "react-div-100vh";
import { Route } from "react-router-dom";

import ErrorBoundary from "~components/ErrorBoundary";
import FullPageSpinner from "~components/FullPageSpinner";
import GlobalModal from "~components/GlobalModal";
import AppModeFrame from "~components/app-mode-frame";
import { env } from "~config";
import { OfflineIndicator } from "~features/offline-mode";
import { useAppHistory } from "~hooks";
import { SentryRoutes } from "~lib/sentry";
import Views from "~views";

import { useLocaleContext } from "../contexts/locales";
import { StyledToastContainer } from "./styled";

const googleMapsLibraries: ["drawing"] = ["drawing"];

const App = () => {
  useAppHistory();
  // It's placed here just to trigger update on whole app when locale changes
  useLocaleContext();

  // IMPORTANT: Higher version of google maps than 3.42.9 crash the Marketplace Markers
  const { isLoaded: isGoogleMapsLoaded } = useJsApiLoader({
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    version: "3.42.9",
    libraries: googleMapsLibraries,
  });

  useEffect(() => {
    if (env.REACT_APP_APP_ENVIRONMENT === "production") {
      log.setDefaultLevel(log.levels.ERROR);
    } else {
      log.enableAll();
    }
  }, []);

  if (!isGoogleMapsLoaded) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <AppModeFrame />

      <OfflineIndicator />

      <GlobalModal />

      <StyledToastContainer limit={3} />

      <ErrorBoundary>
        <Div100vh>
          <SentryRoutes>
            <Route path="/*" element={<Views />} />
          </SentryRoutes>
        </Div100vh>
      </ErrorBoundary>
    </>
  );
};

export default App;
