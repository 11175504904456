import styled from "@emotion/styled";

import { AlertBox } from "@ag/components/AlertBox";
import { ActionButton } from "@ag/components/buttons";
import { H5Typography, style } from "@ag/ui";

export const SButton = styled(ActionButton)`
  & + & {
    margin-top: 8px;
  }
`;

export const SSeparatorSection = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 16px;
`;

export const SSeparator = styled.div`
  flex: 1;
  height: 1px;
  background: ${style.color.grey[200]};
`;

export const SSeparatorText = styled.span`
  ${H5Typography};

  margin: 0 28px;

  text-transform: uppercase;
`;

export const SAlert = styled(AlertBox)`
  margin-top: 20px;
`;
