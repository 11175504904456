import { useMemo } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { AlertBoxType } from "@ag/components/AlertBox";
import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup, FormInput } from "@ag/components/form";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";
import { SFormSubmitButtonContainer } from "~forms/styled";

import { FERTILISER_ID_KEY } from "../../constants";
import {
  FertiliserAddingMode,
  FertiliserAttribute,
  FertiliserDetailsAttribute,
} from "../../types";
import {
  SAlert,
  SSeparator,
  SSeparatorSection,
  SSeparatorText,
} from "../styled";
import { getChemicalSpecificationFertilisers } from "./helpers";
import {
  SApplicationMethodWrapper,
  SChemicalSpecification,
  SFertiliserName,
  SFertiliserRow,
  SInputWrapper,
} from "./styled";

type Props = {
  applicationModes: LabelValue<string>[];
  fertilisers: ISOFertiliser[];
  isReadonly: boolean | undefined;
  isSubmitting: boolean | undefined;
  onAddingModeButtonClick: (addingMode: FertiliserAddingMode) => void;
};

const ChemicalSpecification = ({
  applicationModes,
  fertilisers,
  isReadonly,
  isSubmitting,
  onAddingModeButtonClick,
}: Props) => {
  const { submit, mutators } = useForm();
  const { values, initialValues, pristine } = useFormState();

  const isSubmissionOverwriting =
    initialValues[FertiliserAttribute.FertilisersShowChemicalSpecification] ===
    false;
  const chemicalSpecificationAmounts = values.chemicalSpecification?.fertilisers
    ? Object.values(values.chemicalSpecification.fertilisers)
    : [];
  const isChemicalSpecificationEntered =
    chemicalSpecificationAmounts.filter(amount => amount).length > 0;

  const chemicalSpecificationFertilisers = useMemo(
    () => getChemicalSpecificationFertilisers(fertilisers),
    [fertilisers],
  );

  const onNotUsingFertiliserButtonClicked = () => {
    mutators.resetFertilisers();
    submit();
  };

  return (
    <SChemicalSpecification data-test="fertiliser-chemicalSpecification">
      {chemicalSpecificationFertilisers.map(fertiliser => (
        <SFertiliserRow key={fertiliser.id}>
          <SFertiliserName>{fertiliser.name}</SFertiliserName>

          <SInputWrapper>
            <Field
              type="number"
              name={`chemicalSpecification.${FertiliserAttribute.Fertilisers}.${FERTILISER_ID_KEY}${fertiliser.id}`}
              component={FormInput}
              placeholder="0"
              label={I18n.t("js.carbon.kg_ha")}
              parse={value => Number(value)}
              disabled={isReadonly}
            />
          </SInputWrapper>
        </SFertiliserRow>
      ))}

      <SApplicationMethodWrapper>
        <Field
          name={`chemicalSpecification.${FertiliserDetailsAttribute.ApplicationMethod}`}
          label={I18n.t("js.carbon.application_method")}
          component={FormButtonsGroup}
          buttons={applicationModes}
          hint={I18n.t("js.carbon.application_method_hint")}
          disabled={isReadonly}
        />
      </SApplicationMethodWrapper>

      {isSubmissionOverwriting && (
        <SAlert type={AlertBoxType.Warning}>
          {I18n.t("js.carbon.saving_specification_overwrite_list")}
        </SAlert>
      )}

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          {!isChemicalSpecificationEntered && (
            <ActionButton
              HTMLType="submit"
              type="text"
              testTag="fertiliser-notUsingFertiliserBtn"
              isLoading={isSubmitting}
              onClick={onNotUsingFertiliserButtonClicked}
            >
              {I18n.t("js.carbon.not_using_fertiliser")}
            </ActionButton>
          )}

          <ActionButton
            HTMLType="submit"
            testTag="fertiliser-submitButton"
            disabled={!isChemicalSpecificationEntered || pristine}
            isLoading={isSubmitting}
            onClick={submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}

      {!isReadonly && (
        <>
          <SSeparatorSection>
            <SSeparator />

            <SSeparatorText>{I18n.t("js.shared.or")}</SSeparatorText>

            <SSeparator />
          </SSeparatorSection>

          <ActionButton
            type="secondary"
            testTag="fertilisersPartial-chooseFromList"
            onClick={() => {
              onAddingModeButtonClick(FertiliserAddingMode.List);
            }}
          >
            {I18n.t("js.carbon.choose_from_fertiliser_list")}
          </ActionButton>
        </>
      )}
    </SChemicalSpecification>
  );
};

export default ChemicalSpecification;
