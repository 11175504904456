import styled from "@emotion/styled";

import { AlertBox } from "@ag/components/AlertBox";
import { SModalFooter } from "@ag/components/Modal";
import { ActionButton } from "@ag/components/buttons";
import { media } from "@ag/ui";

type SFormRowProps = {
  margin?: boolean;
};

export const SFormRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;

  > * {
    flex-grow: 1;
    flex-basis: 100%;

    + * {
      margin-left: 24px;
    }
  }

  ${media.sm`
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    > * + * {
      margin-left: 0;
    }
  `}

  ${({ margin }: SFormRowProps): string => {
    let styles = ``;

    if (margin) {
      styles += `
        margin-top: 12px;
      `;
    }

    return styles;
  }}
`;

export const SFooter = styled(SModalFooter)`
  margin-top: 16px;
`;

export const SCancelButton = styled(ActionButton)`
  && {
    padding: 0;
    margin-right: 40px;
  }
`;

export const SAlert = styled(AlertBox)`
  margin-top: -16px;
  margin-bottom: 16px;
`;
