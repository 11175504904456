import styled from "@emotion/styled";

import { ActionButton } from "@ag/components/buttons";

type SAddRoundButtonProps = {
  margin?: boolean;
};

export const SAddApplicationButton = styled(ActionButton)`
  && {
    padding: 0;

    ${({ margin }: SAddRoundButtonProps) => {
      let styles = ``;

      if (margin) {
        styles += `margin-top: 16px;`;
      }

      return styles;
    }}
  }
`;

export const SApplicationWrapper = styled.div`
  & + & {
    margin-top: 8px;
  }
`;

export const SApplicationRoundTitle = styled.h5`
  margin: 0;
  line-height: 24px;
  font-size: 12px;
  text-transform: uppercase;
`;
