import omit from "lodash/omit";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  AriaListBoxOptions,
  mergeProps,
  useComboBox,
  useFilter,
  useListBox,
  useOption,
} from "react-aria";
import { mergeRefs, useLayer } from "react-laag";
import {
  ComboBoxState,
  Item,
  Node,
  Section,
  useComboBoxState,
} from "react-stately";

import { Icon, IconProps } from "~assets";
import { Input } from "~atoms";
import { cn } from "~utils";

declare module "react-stately" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ItemProps<T> {
    icon?: IconProps["name"];
  }
}

/**
 * For some reason this type is not exposed yet:
 * https://github.com/adobe/react-spectrum/issues/4138
 */
type CollectionChildren = Parameters<typeof useComboBoxState>[0]["children"];

/* -------------------------------------------------------------------------------------------------
 * ComboBox
 * -----------------------------------------------------------------------------------------------*/
type ComboBoxProps = React.PropsWithChildrenRequired<{
  emptyText: string;
  loadingText: string;
  value: string;
  className?: string;
  optionsClassName?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  isLoading?: boolean;
  placeholder?: string;
  search?: string;
  variant?: "full-width";
  isDisabled?: boolean;
  isInvalid?: boolean;
  onChange: (value: string) => void;
}> &
  (
    | {
        isAsync: true;
        onInput: (value: string) => void;
      }
    | {
        isAsync?: false;
        onInput?: never;
      }
  );

const ComboBox = ({
  children,
  value,
  className,
  optionsClassName,

  ariaLabel,
  ariaLabelledBy,
  emptyText,
  loadingText,
  placeholder,
  variant,
  isAsync,
  isDisabled,
  isInvalid,
  isLoading,
  onChange,
  onInput = () => {},
}: ComboBoxProps) => {
  // Setup refs and get props for child elements.
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);

  /**
   * Dynamically calculate input width to keep input and options list sized the same,
   * since they are detatched in the DOM so we cannot use CSS.
   */
  const [inputOffsetWidth, setInputOffsetWidth] = useState<number | null>(null);

  /**
   * This is the filter used to filter out items when typing in the input.
   * It uses a simple "contains" method with no case sensitivity.
   */
  const { contains } = useFilter({ sensitivity: "base" });

  const state = useComboBoxState({
    // This option fixes an issue where the first async search result would not show.
    allowsEmptyCollection: true,
    children: children as CollectionChildren,
    defaultFilter: isAsync ? undefined : contains,
    placeholder,
    selectedKey: value || "",
    isDisabled,
    onInputChange: onInput,
    onSelectionChange: key => {
      onChange(key ? key.toString() : "");
      inputRef.current?.blur();
    },
  });

  const { inputProps, listBoxProps } = useComboBox(
    {
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      inputRef,
      listBoxRef,
      placeholder,
      popoverRef,
    },
    state,
  );

  const { triggerProps, layerProps, renderLayer } = useLayer({
    container: () => document.body,
    isOpen: state.isOpen,
    onOutsideClick: () => state.setOpen(false),
    placement: "bottom-start",
    triggerOffset: 4,
  });

  const handleInputUpdate = useCallback((node: HTMLInputElement | null) => {
    setInputOffsetWidth(node ? node.offsetWidth : null);
  }, []);

  return (
    <div
      className={cn(
        "inline-grid",
        {
          "w-full": variant === "full-width",
        },
        className,
      )}
    >
      <Input
        {...mergeProps(omit(inputProps, "onBlur"), omit(triggerProps, "ref"))}
        ref={mergeRefs(inputRef, triggerProps.ref, handleInputUpdate)}
        rightAddon={
          <Icon name="search" onClick={() => inputRef.current?.focus()} />
        }
        disabled={isDisabled}
        isInvalid={isInvalid}
      />

      {state.isOpen &&
        renderLayer(
          <div
            {...omit(layerProps, "ref")}
            ref={mergeRefs(layerProps.ref, popoverRef)}
            className={cn(
              "z-low overflow-hidden rounded",
              "border border-solid border-grey-700 bg-white-100 text-h5 text-grey-900 shadow-200",
              optionsClassName,
            )}
            style={{
              ...layerProps.style,
              minWidth: inputOffsetWidth ?? "auto",
            }}
            data-test="combo-box-options"
          >
            <ComboBoxListBox
              {...listBoxProps}
              emptyText={emptyText}
              listBoxRef={listBoxRef}
              loadingText={loadingText}
              state={state}
              isLoading={isLoading}
            />
          </div>,
        )}
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 * ComboBoxListBox
 * -----------------------------------------------------------------------------------------------*/
type ComboBoxListBoxProps<T extends object> = {
  listBoxRef: React.MutableRefObject<null>;
  emptyText: string;
  loadingText: string;
  state: ComboBoxState<T>;
  isLoading?: boolean;
} & AriaListBoxOptions<T>;

function ComboBoxListBox<T extends object>({
  listBoxRef,
  emptyText,
  loadingText,
  state,
  isLoading,
  ...rest
}: ComboBoxListBoxProps<T>) {
  const ref = useRef(null);
  const { listBoxProps } = useListBox(rest, state, listBoxRef ?? ref);

  const items = useMemo(() => Array.from(state.collection), [state.collection]);

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      /**
       * Max height calculation:
       * - itemLineheight = 14 * 1.5 = 21
       * - itemPaddingBlock = 6
       * - itemHeight = itemPaddingBlock * 2 + itemLineHeight = 33
       * - viewportPaddingBlock = 9
       * - maxHeight = itemHeight * 4.5 + viewportPaddingBlock = 157.5
       */
      className="m-0 grid max-h-[157.5px] list-none overflow-y-scroll px-0 py-2"
    >
      {items.length ? (
        items.map(item => {
          if (item.type === "section") {
            return (
              <>
                <li key={item.key} className="px-4 py-2 text-p3 font-[600]">
                  {item.props.title}
                </li>
                {item.props.children.map((child: Node<T>) => (
                  <ComboBoxItem key={child.key} item={child} state={state} />
                ))}
              </>
            );
          }

          return <ComboBoxItem key={item.key} item={item} state={state} />;
        })
      ) : (
        <ComboBoxEmpty>{isLoading ? loadingText : emptyText}</ComboBoxEmpty>
      )}
    </ul>
  );
}

/* -------------------------------------------------------------------------------------------------
 * ComboBoxOption
 * -----------------------------------------------------------------------------------------------*/
type ComboBoxOptionProps<T> = {
  item: Node<T>;
  state: ComboBoxState<T>;
};

/**
 * Internal component for rendering combo box items (aka options).
 *
 * Note: When consuming ComboBox, react-stately "Item" is used instead,
 * then through hooks uses ComboBoxOption on the inside
 */
function ComboBoxItem<T>({ item, state }: ComboBoxOptionProps<T>) {
  const ref = useRef(null);
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref,
  );

  return (
    <li
      {...optionProps}
      ref={ref}
      className={cn(
        "relative flex max-w-full cursor-pointer items-center justify-between overflow-hidden px-4 py-1.5",
        `select-none text-ellipsis whitespace-nowrap leading-[21px] text-grey-900`,
        {
          "text-color-grey-700 pointer-events-none": isDisabled,
          "bg-grey-200 outline-none": isFocused,
          "text-color-green-400 bg-opal-100": isSelected,
        },
      )}
      data-disabled={isDisabled ?? null}
      data-highlighted={isFocused ?? null}
      data-state={isSelected ? "checked" : null}
    >
      {item.rendered || item.props.children}
      {item.props.icon && <Icon name={item.props.icon} />}
    </li>
  );
}

/* -------------------------------------------------------------------------------------------------
 * ComboBoxEmpty
 * -----------------------------------------------------------------------------------------------*/
function ComboBoxEmpty(props: React.PropsWithChildrenOnly) {
  return <div className="px-4 py-1.5" {...props} />;
}

const Root = ComboBox;

export { Root, Item, Section };
