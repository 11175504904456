import * as SelectPrimitive from "@radix-ui/react-select";
import type { SelectItemProps } from "@radix-ui/react-select";
import { cva } from "class-variance-authority";
import clsx from "clsx";
import { createContext, useContext } from "react";

import { fixedForwardRef } from "@ag/utils/types";

import { Icon } from "~assets";
import { cn } from "~utils";

type SelectVariant = "default" | "full-width" | "compact";

type SelectContextValue = {
  variant: SelectVariant;
};

const SelectContext = createContext<SelectContextValue | null>(null);

function useSelectContext() {
  const context = useContext(SelectContext);

  if (!context) {
    throw new Error("useSelectContext must be used within a SelectProvider");
  }

  return context;
}

/* -------------------------------------------------------------------------------------------------
 * Select
 * -----------------------------------------------------------------------------------------------*/
type SelectProps = React.PropsWithChildrenRequired<{
  className?: string;
  maxChars?: number;
  placeholder?: string;
  value?: string | null;
  variant?: "default" | "full-width" | "compact";
  optionsClassName?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  testId?: string;
  onChange: (value: string) => void;
}>;

const triggerVariants = cva(
  [
    "group",
    "inline-flex items-center justify-between gap-3",
    "min-w-[17ch] px-4 py-[9px]",
    "whitespace-nowrap",
    "rounded",
    "border border-solid border-grey-700 bg-white-100",
    "cursor-pointer text-input",
    "data-[placeholder]:text-grey-700",
    "focus:shadow-[0_0_0_3px_rgba(255,197,141)] focus:outline-none",
    "[&+select]:top-0",
  ],
  {
    variants: {
      variant: {
        "full-width": "w-full",
        compact: "min-w-[auto]",
        default: {},
      },
      isInvalid: {
        true: "border-red-700",
      },
      isDisabled: {
        true: "border-grey-200 bg-grey-200",
      },
    },
    compoundVariants: [
      {
        isInvalid: false,
        isDisabled: false,
        class: "hover:border-green-400 focus:border-green-400",
      },
    ],
  },
);

const Select = fixedForwardRef(SelectComponent);

function SelectComponent(
  {
    children,
    placeholder,
    className,
    optionsClassName,
    value,
    variant = "default",
    isDisabled = false,
    isInvalid = false,
    onChange,
    testId,
  }: SelectProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <SelectContext.Provider value={{ variant }}>
      <SelectPrimitive.Root
        value={value === null ? undefined : value}
        disabled={isDisabled === true}
        onValueChange={onChange}
      >
        <SelectPrimitive.Trigger
          data-test={testId}
          className={clsx(
            triggerVariants({ variant, isDisabled, isInvalid }),
            className,
          )}
          ref={ref}
        >
          <span
            className={cn(
              "inline-block truncate",
              variant === "full-width"
                ? "max-w-[calc(100%-32px)]"
                : "max-w-[32ch]",
            )}
          >
            <SelectPrimitive.Value placeholder={placeholder} />
          </span>

          <SelectPrimitive.Icon
            className={cn(
              "text-green-500",
              "group-data-[disabled]:text-gray-700 group-data-[state=open]:rotate-180",
            )}
          >
            <Icon name="chevron-down" />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={cn(
              "rounded border border-grey-700 bg-white-100",
              "w-full min-w-[--radix-select-trigger-width] overflow-hidden",
              "z-low text-h5 text-grey-900 shadow-200",
              optionsClassName,
            )}
            position="popper"
            sideOffset={4}
          >
            <SelectPrimitive.Viewport className="relative grid max-h-40 overflow-y-scroll py-2">
              {children}
            </SelectPrimitive.Viewport>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </SelectContext.Provider>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SelectOption
 * -----------------------------------------------------------------------------------------------*/
function SelectOption(props: SelectItemProps) {
  const { value, className, children, ...rest } = props;

  const context = useSelectContext();

  return (
    <SelectPrimitive.Item
      value={value}
      {...rest}
      className={cn(
        "flex items-center",
        "relative px-4 py-2",
        "user-select-none cursor-pointer text-grey-900",
        "data-[disabled]:pointer-events-none data-[disabled]:text-grey-700",
        "data-[highlighted]:bg-grey-200",
        "data-[state=checked]:bg-opal-100 data-[state=checked]:text-green-400",
        className,
      )}
    >
      <span
        className={cn(
          "inline-block truncate",
          context.variant === "full-width"
            ? "max-w-[calc(100%-32px)]"
            : "max-w-[32ch]",
        )}
        // used to let the user read full text when using truncation
        title={typeof children === "string" ? children : undefined}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </span>
    </SelectPrimitive.Item>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SelectOptionAll
 * -----------------------------------------------------------------------------------------------*/
function SelectOptionAll({
  children,
  ...rest
}: Omit<SelectItemProps, "value">) {
  return (
    <SelectOption {...rest} value="">
      {children}
    </SelectOption>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SelectOptionNone
 * -----------------------------------------------------------------------------------------------*/
function SelectOptionNone({
  children,
  ...rest
}: Omit<SelectItemProps, "value">) {
  return (
    <SelectOption {...rest} value="">
      {children}
    </SelectOption>
  );
}

const Root = Select;
const Option = SelectOption;
const OptionAll = SelectOptionAll;
const OptionNone = SelectOptionNone;

export { Root, Option, OptionAll, OptionNone };
