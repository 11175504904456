import { ValidationErrors } from "final-form";

import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { CROP_TYPE_OTHER_OPTION_ID } from "~constants";
import { CropType } from "~entities";
import {
  CoverCrops,
  CropResidueManagement,
  TillingPracticeValue,
} from "~types";

import {
  CultivationStrategyAttribute,
  GetInputVisibilityStateArgs,
  InputVisibility,
  ValidateArgs,
} from "./types";

export const getCropTypeOptions = (
  cropTypes: CropType[],
): LabelValue<unknown>[] => {
  return cropTypes.map(({ name, id }) => ({
    label: name,
    value: String(id),
  }));
};

export const getTillingCoverCropsOptions = (): LabelValue<CoverCrops>[] => [
  {
    label: I18n.t("js.shared.no"),
    value: CoverCrops.None,
  },
  {
    label: I18n.t("js.carbon.up_until_nov"),
    value: CoverCrops.Winter,
  },
  {
    label: I18n.t("js.carbon.up_until_sowing"),
    value: CoverCrops.Spring,
  },
  {
    label: I18n.t("js.carbon.catch_crops"),
    value: CoverCrops.Catch,
  },
];

export const getTillingPracticeOptions = (): LabelValue<string>[] => [
  {
    label: I18n.t("js.carbon.tilled"),
    value: String(TillingPracticeValue.Conventional),
  },
  {
    label: I18n.t("js.carbon.reduced_tillage"),
    value: String(TillingPracticeValue.ReducedTillage),
  },
  {
    label: I18n.t("js.carbon.no-till"),
    value: String(TillingPracticeValue.NoTill),
  },
];

export const getResidueManagementOptions = (
  initialOptions: LabelValue<string>[],
): LabelValue<string>[] =>
  initialOptions.map(option => {
    if (option.value === CropResidueManagement.Burned) {
      return {
        ...option,
        warning: I18n.t("js.carbon.burning_against_policy"),
      };
    }

    return option;
  });

export const getInputVisibilityState = ({
  values,
  cropTypes,
}: GetInputVisibilityStateArgs): InputVisibility => {
  const selectedCropTypeOption = cropTypes.find(
    (cropType): boolean =>
      cropType.id === values[CultivationStrategyAttribute.CarbonCropTypeId],
  );
  const isResidueManagementInputVisible = Boolean(
    selectedCropTypeOption?.showResidue ?? true,
  );
  const isCustomCropTypeInputVisible =
    selectedCropTypeOption?.identifier === CROP_TYPE_OTHER_OPTION_ID;

  return {
    isResidueManagementInputVisible,
    isCustomCropTypeInputVisible,
  };
};

export const validate = ({ values, cropTypes }: ValidateArgs) => {
  const { isCustomCropTypeInputVisible, isResidueManagementInputVisible } =
    getInputVisibilityState({ values, cropTypes });

  const errors: ValidationErrors = {};

  if (!values[CultivationStrategyAttribute.CropLastHarvestYear]) {
    errors[CultivationStrategyAttribute.CropLastHarvestYear] = I18n.t(
      "js.carbon.select_last_harvest_year",
    );
  }

  if (!values[CultivationStrategyAttribute.CarbonCropTypeId]) {
    errors[CultivationStrategyAttribute.CarbonCropTypeId] = I18n.t(
      "js.carbon.select_crop_type",
    );
  }

  if (
    isCustomCropTypeInputVisible &&
    !values[CultivationStrategyAttribute.CropTypeOtherName]
  ) {
    errors[CultivationStrategyAttribute.CropTypeOtherName] = I18n.t(
      "js.carbon.enter_crop_type_value",
    );
  }

  if (!values[CultivationStrategyAttribute.CropGrossYield]) {
    errors[CultivationStrategyAttribute.CropGrossYield] = I18n.t(
      "js.carbon.enter_gross_yield_in_tonnes",
    );
  } else {
    if ((values[CultivationStrategyAttribute.CropGrossYield] as number) <= 0) {
      errors[CultivationStrategyAttribute.CropGrossYield] = I18n.t(
        "js.carbon.enter_valid_gross_yield",
      );
    }

    if ((values[CultivationStrategyAttribute.CropGrossYield] as number) > 200) {
      errors[CultivationStrategyAttribute.CropGrossYield] = I18n.t(
        "js.carbon.enter_valid_gross_yield_max",
      );
    }
  }

  if (
    isResidueManagementInputVisible &&
    !values[CultivationStrategyAttribute.CropResidueManagement]
  ) {
    errors[CultivationStrategyAttribute.CropResidueManagement] = I18n.t(
      "js.carbon.select_residue_management",
    );
  }

  if (values[CultivationStrategyAttribute.TillingRate] == null) {
    errors[CultivationStrategyAttribute.TillingRate] = I18n.t(
      "js.shared.please_choose_option",
    );
  }

  if (values[CultivationStrategyAttribute.CoverCropsAdoption] == null) {
    errors[CultivationStrategyAttribute.CoverCropsAdoption] = I18n.t(
      "js.shared.please_choose_option",
    );
  }

  return errors;
};
