import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup, FormInput, FormSelect } from "@ag/components/form";
import { SCol, SPartialForm, SRow } from "@ag/components/styled";
import I18n from "@ag/i18n";
import { getYesNoOptions } from "@ag/utils/helpers";
import { LabelValue } from "@ag/utils/types";

import { CropType } from "~entities";
import { SFormSubmitButtonContainer } from "~forms/styled";

import {
  getInputVisibilityState,
  getResidueManagementOptions,
  getTillingCoverCropsOptions,
  getTillingPracticeOptions,
} from "./helpers";
import {
  CultivationStrategyAttribute,
  CultivationStrategyV2021Data,
} from "./types";

type Props = {
  cropTypes: CropType[];
  cropResidueManagementOptions: LabelValue<string>[];
  isReadonly: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<CultivationStrategyV2021Data>) => void;
};

export const CultivationStrategyForm = ({
  cropTypes,
  cropResidueManagementOptions,
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<CultivationStrategyV2021Data>();
  const { values, pristine } = useFormState<CultivationStrategyV2021Data>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const tillingCoverCropsOptions = getTillingCoverCropsOptions();
  const tillingPracticeOptions = getTillingPracticeOptions();
  const residueManagementOptions = getResidueManagementOptions(
    cropResidueManagementOptions,
  );

  const { isCustomCropTypeInputVisible, isResidueManagementInputVisible } =
    getInputVisibilityState({ values, cropTypes });

  return (
    <SPartialForm>
      <SRow>
        <SCol md={12}>
          <Field
            label={I18n.t("js.carbon.crop_type")}
            tip={I18n.t("js.carbon.crop_type_instruction")}
            placeholder={I18n.t("js.carbon.crop_type_select_placeholder")}
            testTag="cultivationStrategy-cropTypeSelect"
            name={CultivationStrategyAttribute.CarbonCropTypeId}
            component={FormSelect}
            options={cropTypes.map(cropType => ({
              label: cropType.name,
              value: cropType.id,
            }))}
            isSearchable
            isDisabled={isReadonly}
          />
        </SCol>

        {isCustomCropTypeInputVisible && (
          <SCol md={12}>
            <Field
              label={I18n.t("js.carbon.other_type")}
              name={CultivationStrategyAttribute.CropTypeOtherName}
              component={FormInput}
              disabled={isReadonly}
            />
          </SCol>
        )}

        <SCol>
          <Field
            name={CultivationStrategyAttribute.CropSeedTreatment}
            label={I18n.t("js.carbon.have_seed_been_treated")}
            wrapperTestTag="cultivationStrategy-cropSeedTreatmentButtonGroup"
            component={FormButtonsGroup}
            disabled={isReadonly}
            buttons={getYesNoOptions()}
          />
        </SCol>
      </SRow>

      <SRow>
        <SCol col={6} md={12}>
          <Field
            testTag="cultivationStrategy-cropGrossYieldInput"
            label={I18n.t("js.carbon.gross_yield_unit")}
            name={CultivationStrategyAttribute.CropGrossYield}
            component={FormInput}
            type="number"
            disabled={isReadonly}
            tooltip={I18n.t("js.carbon.gross_yield_instruction")}
            parse={value => value && Number(value)}
          />
        </SCol>

        <SCol col={6} md={12}>
          {isResidueManagementInputVisible && (
            <Field
              wrapperTestTag="cultivationStrategy-residueManagementButtonGroup"
              label={I18n.t("js.carbon.residue_management")}
              name={CultivationStrategyAttribute.CropResidueManagement}
              component={FormButtonsGroup}
              buttons={residueManagementOptions}
              tooltip={I18n.t("js.carbon.residue_management_tooltip")}
              disabled={isReadonly}
            />
          )}
        </SCol>
      </SRow>

      <SRow>
        <SCol col={6} md={12}>
          <Field
            label={I18n.t("js.carbon.cover_crops_adoption")}
            placeholder={I18n.t("js.carbon.choose_interval")}
            wrapperTestTag="cultivationStrategy-coverCropsAdoptionButtonGroup"
            name={CultivationStrategyAttribute.CoverCropsAdoption}
            component={FormButtonsGroup}
            buttons={tillingCoverCropsOptions}
            disabled={isReadonly}
          />
        </SCol>
        <SCol col={6} md={12}>
          <Field
            wrapperTestTag="cultivationStrategy-tillingRateButtonGroup"
            label={I18n.t("js.carbon.tilling_practice")}
            placeholder={I18n.t("js.carbon.choose_interval")}
            name={CultivationStrategyAttribute.TillingRate}
            component={FormButtonsGroup}
            buttons={tillingPracticeOptions}
            disabled={isReadonly}
            tooltip={I18n.t(
              "js.farmer.carbon.field_details.tilling_practice_tooltip",
            )}
          />
        </SCol>
      </SRow>

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          <ActionButton
            HTMLType="submit"
            testTag="cultivationStrategy-submitButton"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}
    </SPartialForm>
  );
};
