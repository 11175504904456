import { z } from "zod";

import { LabelValueSchema } from "@ag/utils/schemas";

import { HarvestYearsDataSchema, YearFlowDataSchema } from "../schemas";

export const CarbonInitialResourcesSchema = z.object({
  certificatePriceCurrency: z.string(),
  currentHarvestYear: z.number(),
  certificatePrice: z.number(),
  field: z.object({
    cropResidueManagement: z.array(LabelValueSchema(z.string())),
    energyConsumptionAmountUnit: z.array(LabelValueSchema(z.string())),
    energyConsumptionEnergySource: z.array(LabelValueSchema(z.string())),
    cropProtectionsCategory: z.record(
      z.string(),
      z.array(LabelValueSchema(z.string())),
    ),
    cropProtectionsProtectionType: z.record(
      z.string(),
      z.array(LabelValueSchema(z.string())),
    ),
    fertiliserApplicationMode: z.array(LabelValueSchema(z.string())),
    soilDrainage: z.array(LabelValueSchema(z.string())),
    soilMoisture: z.array(LabelValueSchema(z.string())),
    soilPhRange: z.array(LabelValueSchema(z.string())),
    soilSomRange: z.array(LabelValueSchema(z.string())),
    soilTexture: z.array(LabelValueSchema(z.string())),
  }),
  harvestYearsActive: z.array(z.number()),
  showValidationBanner: z.boolean(),
  currentBrokerYear: z.number(),
  currentContractYear: z.number().optional(),
  certificatesStatsRangeOffset: z.number(),
  methodologyVersions: z.object({
    all: z.array(z.string()),
    current: z.string(),
  }),
});

export const YearsDataSchema = z.object({
  brokerableYears: z.array(z.number()),
  harvestYearData: HarvestYearsDataSchema,
  contractedYearsData: z.object({
    contractedYears: z.array(z.number()),
  }),
  yearFlowData: YearFlowDataSchema,
});
