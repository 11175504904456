"use client";

import centroid from "@turf/centroid";
import {
  lineString,
  multiPolygon as turfMultiPolygon,
  polygon as turfPolygon,
} from "@turf/helpers";
import intersect from "@turf/intersect";
import lineIntersect from "@turf/line-intersect";
import L from "leaflet";

import { GeoJSON, GeoJSONGeometry, GeometryType } from "../types/geo-json";
import { LatLng } from "../types/lat-lng";

/**
 * GeoJSON is a format Lng first, Lat second, but Leaflet is Lat first, Lng second.
 * @param geojsonGeometry GeoJSON geometry
 * @returns Leaflet LatLng coordinates
 *
 */
export const getGeojsonGeometryLeafletLatLngCoordinates = (
  geojsonGeometry: GeoJSONGeometry | undefined | null,
) => {
  const depth = {
    [GeometryType.LineString]: 0,
    [GeometryType.Polygon]: 1,
    [GeometryType.MultiPolygon]: 2,
  };

  if (!geojsonGeometry) return [];

  return L.GeoJSON.coordsToLatLngs(
    geojsonGeometry.coordinates,
    depth[geojsonGeometry.type],
  );
};

/**
 * GeoJSON is a format Lng first, Lat second, but Leaflet is Lat first, Lng second.
 * @param geojsonGeometry GeoJSON geometry
 * @returns GoogleMaps LatLng coordinates for LineString - [], Polygon - [][], MultiPolygon - [][][]
 */
export const getGeojsonGeometryGoogleMapsLatLngCoordinates = (
  geojsonGeometry: GeoJSONGeometry | undefined | null,
): google.maps.LatLng[] | google.maps.LatLng[][] | google.maps.LatLng[][][] => {
  if (!geojsonGeometry) return [];

  const { type, coordinates } = geojsonGeometry;

  // Helper function to recursively convert coordinates as there different levels of nesting
  const convertCoordinates = (coords: any) => {
    if (Array.isArray(coords[0])) {
      return coords.map((coord: any) => convertCoordinates(coord));
    } else {
      const [lng, lat] = coords;
      return new google.maps.LatLng(lat, lng);
    }
  };

  switch (type) {
    case GeometryType.LineString:
      return convertCoordinates(coordinates);
    case GeometryType.Polygon:
      return coordinates.map(coord => convertCoordinates(coord));
    case GeometryType.MultiPolygon:
      return coordinates.map(polygon =>
        polygon.map(coord => convertCoordinates(coord)),
      );
    default:
      throw new Error("Unsupported geometry type");
  }
};

export const getIsPathPointsInsidePolygon = (
  innerPath: google.maps.LatLng[],
  polygon: google.maps.Polygon,
) =>
  innerPath.every(point =>
    google.maps.geometry.poly.containsLocation(point, polygon),
  );

export const isAnyOfPathPointsInsidePolygon = (
  innerPath: google.maps.LatLng[],
  polygon: google.maps.Polygon,
) =>
  innerPath.some(point =>
    google.maps.geometry.poly.containsLocation(point, polygon),
  );

/**
 * Get GeoJSON coordinates from polygon paths (outer and inner) and make sure that the first and last points are the same (valid GeoJSON polygon)
 * @param outerPath outer path of polygon
 * @param innerPaths inner paths of polygon
 * @returns GeoJSON coordinates
 */
export const getGeoJSONCoordinatesFromPolygonPaths = (
  outerPath: google.maps.LatLng[],
  innerPaths: google.maps.LatLng[][],
) => {
  const outerCoordinates = outerPath.map(({ lng, lat }) => [lng(), lat()]);
  const innerCoordinates = innerPaths.map(innerPath =>
    innerPath.map(({ lng, lat }) => [lng(), lat()]),
  );

  const lastOuterPoint = outerCoordinates[outerCoordinates.length - 1];
  const firstOuterPoint = outerCoordinates[0];

  // Valid GeoJSON polygon requires that the first and last points are the same
  if (
    lastOuterPoint[0] !== firstOuterPoint[0] || // lng
    lastOuterPoint[1] !== firstOuterPoint[1] // lat
  ) {
    outerCoordinates.push(firstOuterPoint);
  }

  innerCoordinates.forEach(innerCoordinates => {
    const firstInnerPoint = innerCoordinates[0];
    const lastInnerPoint = innerCoordinates[innerCoordinates.length - 1];

    // Valid GeoJSON polygon requires that the first and last points are the same
    if (
      lastInnerPoint[0] !== firstInnerPoint[0] || // lng
      lastInnerPoint[1] !== firstInnerPoint[1] // lat
    ) {
      innerCoordinates.push(firstInnerPoint);
    }
  });

  return [outerCoordinates, ...innerCoordinates];
};

/**
 * Get type of path (inner or outer) based on path index, 0 is outer, 1+ is inner in GeoJSON format
 * @param path Path to check
 * @returns 'inner' if path is inner, 'outer' if path is outer
 */
export const getPathType = (path: number): "inner" | "outer" =>
  path > 0 ? "inner" : "outer";

/**
 * Get type of vertex update
 * As we are using GeoJSON format, we need to check if updated vertex is inner or outer
 */
export const getVertexUpdateType = (event: google.maps.PolyMouseEvent) => {
  const { path } = event;

  // Path checked against undefined because it can be 0
  if (path === undefined) return;

  return getPathType(path);
};

// Index of the path and vertex of the interaction
type VertexData = {
  path: number;
  vertex: number;
};

const DEFAULT_INTERSECTIONS = 2;

/**
 * Helper function for checking intersections of interacted vertex with all of the polygon edges
 * Important note: there will always be at least two intersections
 *
 * @param polygon Polygon to check the intersections within
 * @param vertexData Index of the interacted vertex and path
 * @returns Is the vertex intersecting with the polygon
 */
export const getIsVertexIntersecting = (
  polygon: google.maps.Polygon,
  { path, vertex }: VertexData,
) => {
  // Get all vertexes of the interacted polygon (outer or one of the inner ones of the field polygon)
  const interactedPolygonVertexes = polygon.getPaths().getAt(path).getArray();

  // Get two closest edges from the vertex
  const vertexEdges = [
    [
      interactedPolygonVertexes[vertex - 1] || interactedPolygonVertexes[0],
      interactedPolygonVertexes[vertex],
    ],
    [
      interactedPolygonVertexes[vertex],
      interactedPolygonVertexes[vertex + 1] || interactedPolygonVertexes[0],
    ],
  ];

  // Convert array of the polygon vertexes into edges tuples
  // Note: we need to add first vertex to the end of the array to get the last edge
  const polygonEdges: google.maps.LatLng[][] = polygon
    .getPaths()
    .getArray()
    .flatMap(path => {
      const pathVertexes = path.getArray();

      return [...pathVertexes, pathVertexes[0]].reduce(
        (acc, vertex, index, polygon) => {
          if (index === 0) return acc;

          return [...acc, [polygon[index - 1], vertex]];
        },
        [] as google.maps.LatLng[][],
      );
    });

  // Filter out edges that are checked (vertex edges)
  const polygonEdgesToCheck = polygonEdges.filter(polygonEdge => {
    const isVertexEdgeToCheck = vertexEdges.some(
      vertexEdge =>
        polygonEdge[0].equals(vertexEdge[0]) &&
        polygonEdge[1].equals(vertexEdge[1]),
    );

    return !isVertexEdgeToCheck;
  });

  let intersections = 0;

  // Check if any of the vertex edges intersect with any of the polygon edges
  vertexEdges.forEach(vertexEdge => {
    polygonEdgesToCheck.forEach(polygonEdge => {
      const vertexEdgeString = lineString([
        // GeoJSON, so lng first, then lat
        [vertexEdge[0].lng(), vertexEdge[0].lat()],
        [vertexEdge[1].lng(), vertexEdge[1].lat()],
      ]);

      const polygonEdgeString = lineString([
        // GeoJSON, so lng first, then lat
        [polygonEdge[0].lng(), polygonEdge[0].lat()],
        [polygonEdge[1].lng(), polygonEdge[1].lat()],
      ]);

      const result = lineIntersect(vertexEdgeString, polygonEdgeString);

      if (result.features.length > 0) {
        intersections += 1;
      }
    });
  });

  return intersections > DEFAULT_INTERSECTIONS;
};

export const polygonToGeoJSONCoordinates = (polygon: google.maps.Polygon) => {
  const paths = polygon.getPaths().getArray();

  const outerPath = paths[0].getArray();
  const innerPaths = paths.slice(1).map(path => path.getArray());

  return getGeoJSONCoordinatesFromPolygonPaths(outerPath, innerPaths);
};

export const isPolygonSelfIntersecting = (polygon: google.maps.Polygon) => {
  const verticesAmount = polygon.getPath().getLength();

  let isSelfIntersecting = false;

  for (let index = 0; index < verticesAmount; index++) {
    const isVertexIntersecting = getIsVertexIntersecting(polygon, {
      vertex: index,
      // it's does check only outer path so index is 0
      path: 0,
    });

    if (isVertexIntersecting) {
      isSelfIntersecting = true;

      break;
    }
  }

  return isSelfIntersecting;
};

export const getTurfPolygonFromGooglePolygon = (
  polygon: google.maps.Polygon,
) => {
  try {
    const polygonGeoJSONCoordinates = polygonToGeoJSONCoordinates(polygon);

    return turfPolygon(polygonGeoJSONCoordinates);
  } catch (error) {
    console.error(
      "Error while converting google polygon to turf polygon",
      error,
    );

    return null;
  }
};

/**
 * Helper function for checking if one polygon intersect with other polygons edges
 * Important note: there will always be at least two intersections
 *
 * @param polygon Polygon to check the intersections within
 * @param polygons Polygons to check if polygon intersect with
 * @returns Is the polygon intersect with other polygons
 */
export const getIsPolygonIntersectingOtherPolygons = (
  polygon: google.maps.Polygon,
  otherPolygons: google.maps.Polygon[],
) => {
  const turfPolygonA = getTurfPolygonFromGooglePolygon(polygon);

  if (!turfPolygonA) return false;

  return otherPolygons.some(otherPolygon => {
    const turfPolygonB = getTurfPolygonFromGooglePolygon(otherPolygon);

    if (!turfPolygonB) return false;

    const intersection = intersect(turfPolygonA, turfPolygonB);

    return intersection !== null;
  });
};

/**
 * Returns bounds of single polygon.
 */
export const getPolygonBounds = (polygon: google.maps.Polygon) => {
  const bounds = new google.maps.LatLngBounds();

  polygon.getPath().forEach(latLng => {
    bounds.extend(latLng);
  });

  return bounds;
};

/**
 * Returns centroid of a single polygon.
 */
export const calculatePolygonCentroid = (
  coordinates: [number, number][][],
): LatLng => {
  const poly = turfPolygon(coordinates);
  const center = centroid(poly);
  const [lng, lat] = center.geometry.coordinates;

  return { lng, lat };
};

/**
 * Returns centroid of a Multipolygon.
 */
export const calculateMultipolygonCentroid = (
  coordinates: [number, number][][][],
): LatLng => {
  const multipoly = turfMultiPolygon(coordinates);
  const center = centroid(multipoly);
  const [lng, lat] = center.geometry.coordinates;

  return { lng, lat };
};

/**
 * Returns bounds of a GeoJSON collection so that all features are visible on the map.
 * To be used with fitBounds method of Google Maps API.
 * @param geoJsonCollection Array of GeoJSON objects
 */
export const getGeojsonCollectionBounds = <
  F extends { bbox: GeoJSON["bbox"] | null },
>(
  fields: F[],
) => {
  let minLng = Infinity;
  let maxLng = -Infinity;
  let minLat = Infinity;
  let maxLat = -Infinity;

  fields?.forEach(field => {
    if (field.bbox) {
      const [minX, minY, maxX, maxY] = field.bbox;

      minLng = Math.min(minLng, minX);
      maxLng = Math.max(maxLng, maxX);
      minLat = Math.min(minLat, minY);
      maxLat = Math.max(maxLat, maxY);
    }
  });

  const bounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(minLat, minLng),
    new google.maps.LatLng(maxLat, maxLng),
  );

  return bounds;
};

/**
 * Returns center of a GeoJSON object. To be used with center method of Google Maps API.
 * @param bounds Bounds of a GeoJSON object
 */
export const getBoundsCenter = (bounds: google.maps.LatLngBounds) => {
  const center = bounds.getCenter();

  return center
    ? new google.maps.LatLng(center.lat(), center.lng())
    : undefined;
};

export const centerMap = <F extends { bbox: GeoJSON["bbox"] | null }>(
  map: google.maps.Map,
  fields: F[],
) => {
  const bounds = getGeojsonCollectionBounds(fields);
  const center = getBoundsCenter(bounds);

  map.fitBounds(bounds);
  if (center) map.setCenter(center);
};

/**
 * Converts normalized coordinates to an SVG path string.
 * To be used when rendering Polygons/MultiPolygons not on a map.
 *
 * @param coordinates The coordinates to normalize. Can be a polygon or multipolygon.
 * @param bbox The bounding box [minX, minY, maxX, maxY] used to normalize the coordinates.
 * @returns The SVG path.
 */
export const getSVGFromCoordinates = (
  geometryType: GeometryType,
  coordinates: number[][][] | number[][][][],
  bbox: [number, number, number, number],
  svgWidth: number = 28,
  svgHeight: number = 28,
) => {
  const [minX, minY, maxX, maxY] = bbox;
  const width = maxX - minX;
  const height = maxY - minY;

  const normalizeRing = (ring: number[][]) => {
    return ring.map(point => {
      const [x, y] = point;
      const normalizedX = ((Number(x) - minX) / width) * svgWidth;
      const normalizedY = ((Number(maxY) - Number(y)) / height) * svgHeight;
      return [normalizedX, normalizedY];
    });
  };

  const createPath = (ring: number[][]) => {
    return (
      "M" +
      ring
        .map(point => {
          return point.join(",");
        })
        .join("L") +
      "Z"
    );
  };

  if (geometryType === GeometryType.Polygon) {
    const normalizedCoordinates = (coordinates as number[][][]).map(
      normalizeRing,
    );
    return normalizedCoordinates.map(createPath).join(" ");
  } else if (geometryType === GeometryType.MultiPolygon) {
    const normalizedCoordinates = (coordinates as number[][][][]).map(polygon =>
      polygon.map(normalizeRing),
    );
    return normalizedCoordinates
      .map(polygon => polygon.map(createPath).join(" "))
      .join(" ");
  }
};
