import { create } from "zustand";

import { ModalSizes } from "@ag/components/Modal";

import { EditFieldExtras } from "~components/GlobalModal/modals/field/EditField";
import { FieldMapExtras } from "~components/GlobalModal/modals/field/FieldMap";
import { AddMoreFieldsToValidationRequestExtras } from "~components/GlobalModal/modals/validation-flow/AddMoreFieldsToValidationRequest";
import { CreateValidationRequestForAnotherYearExtras } from "~components/GlobalModal/modals/validation-flow/CreateValidationRequestForAnotherYear";

export enum GlobalModalName {
  AddMoreFieldsToValidationRequest = "ADD_MORE_FIELDS_TO_VALIDATION_REQUEST",
  AddFieldsUnavailable = "ADD_FIELDS_UNAVAILABLE",
  CreateValidationRequestForAnotherYear = "CREATE_VALIDATION_REQUEST_FOR_ANOTHER_YEAR",
  EditField = "EDIT_FIELD",
  FieldMap = "FieldMap",
  TranslatorUsageWarning = "TRANSLATOR_USAGE_WARNING",
  ResaleContractSentSuccess = "RESALE_CONTRACT_SENT_SUCCESS",
}

type GlobalModalConfig = {
  size?: ModalSizes;
  noPadding?: boolean;
  top?: string;
  overflowVisible?: boolean;
  disableOverlayClose?: boolean;
  maxHeight?: string;
  style?: React.CSSProperties;
  hideCloseButton?: boolean;
};

export type GlobalModalExtras<T = Record<string, any>> = T;

export type SetActiveModalPayload = {
  config?: GlobalModalConfig | null;
  onSuccess?: (() => void) | null;
  onClose?: (() => void) | null;
} & (
  | {
      name: Extract<
        GlobalModalName,
        | GlobalModalName.AddFieldsUnavailable
        | GlobalModalName.ResaleContractSentSuccess
        | GlobalModalName.TranslatorUsageWarning
      > | null;
      extras?: null;
    }
  | {
      name: GlobalModalName.AddMoreFieldsToValidationRequest;
      extras: AddMoreFieldsToValidationRequestExtras;
    }
  | {
      name: GlobalModalName.CreateValidationRequestForAnotherYear;
      extras: CreateValidationRequestForAnotherYearExtras;
    }
  | {
      name: GlobalModalName.EditField;
      extras: EditFieldExtras;
    }
  | {
      name: GlobalModalName.FieldMap;
      extras: FieldMapExtras;
    }
);

type GlobalModalStore = {
  name: GlobalModalName | null;
  config: GlobalModalConfig | null;
  extras: GlobalModalExtras | null;
  openModal: (payload: SetActiveModalPayload) => void;
  closeModal: () => void;
  onSuccess?: (() => void) | null;
  onClose?: (() => void) | null;
};

export type GlobalModalProps = GlobalModalStore;

export const useGlobalModalStore = create<GlobalModalStore>()(set => ({
  name: null,
  config: null,
  extras: null,
  openModal: payload => set(() => payload),
  closeModal: () =>
    set(() => ({
      name: null,
      config: null,
      extras: null,
      onSuccess: null,
      onClose: null,
    })),
}));
