export enum Feature {
  Carbon = "carbon",
  CarbonAddFields = "carbon_add_fields",
  CarbonContractsSigning = "carbon_contracts_signing",
  CarbonCreateFieldActuals = "carbon_create_field_actuals",
  CarbonDashboard = "carbon_dashboard",
  CarbonFarmerDashboard = "carbon_farmer_dashboard",
  CarbonFarms = "carbon_farms",
  CarbonTemplates = "carbon_templates",
  CarbonSimplifiedDefinition = "carbon_simplified_definition",
  DisplayCarbonContracts = "display_carbon_contracts",
  Hubspot = "hubspot",
}
