import { ICONS_REGULAR, Icon } from "~assets";
import { Button, ButtonProps } from "~atoms";
import { cn } from "~utils";

type RootProps = {
  children: (React.ReactElement<
    React.PropsWithChildrenRequired | ButtonProps
  > | null)[];
  backgroundVariant?: "white" | "grey";
  alignVariant?: "left" | "center";
  className?: string;
};
export const Root = ({
  backgroundVariant = "white",
  alignVariant = "center",
  children,
  className,
}: RootProps) => (
  <div
    className={cn(
      "flex flex-col gap-6 p-10",
      {
        "bg-gray-100": backgroundVariant === "grey",
        "items-start text-left": alignVariant === "left",
        "items-center text-center": alignVariant === "center",
      },
      className,
    )}
  >
    {children}
  </div>
);

/* -------------------------------------------------------------------------------------------------
 * Image
 * -----------------------------------------------------------------------------------------------*/
type ImageProps = {
  name?: keyof typeof ICONS_REGULAR;
};

export const Image = ({ name = "face-monocle" }: ImageProps) => (
  <Icon className="text-[56px]" name={name} />
);

/* -------------------------------------------------------------------------------------------------
 * Title
 * -----------------------------------------------------------------------------------------------*/
export const Title = ({ children }: React.PropsWithChildrenRequired) => {
  return <h3 className="text-h3">{children}</h3>;
};

/* -------------------------------------------------------------------------------------------------
 * Description
 * -----------------------------------------------------------------------------------------------*/
export const Description = ({ children }: React.PropsWithChildrenRequired) => (
  <p className="-mt-2 text-p2">{children}</p>
);

/* -------------------------------------------------------------------------------------------------
 * Primary button
 * -----------------------------------------------------------------------------------------------*/
export const PrimaryButton = (props: ButtonProps) => (
  <Button {...props} variant="primary" size="medium" />
);

/* -------------------------------------------------------------------------------------------------
 * Secondary button
 * -----------------------------------------------------------------------------------------------*/
export const SecondaryButton = (props: ButtonProps) => (
  <Button {...props} variant="text" size="medium" />
);
