import { useState } from "react";

import { ActionButton } from "@ag/components/buttons";
import I18n from "@ag/i18n";

import LearnMoreModal from "./LearnMoreModal";
import { SDescription, SLearnMoreButtonContainer, SRoot } from "./styled";

type Props = {
  description: string;
  modalText: string;
};

const LearnMore = ({ description, modalText }: Props) => {
  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false);

  return (
    <>
      <SRoot>
        <SDescription
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />

        <SLearnMoreButtonContainer>
          <ActionButton
            type="text"
            onClick={() => setIsLearnMoreModalOpen(true)}
          >
            {I18n.t("js.carbon.learn_more")}
          </ActionButton>
        </SLearnMoreButtonContainer>
      </SRoot>

      <LearnMoreModal
        text={modalText}
        isModalOpen={isLearnMoreModalOpen}
        onModalClose={() => setIsLearnMoreModalOpen(false)}
      />
    </>
  );
};

export default LearnMore;
