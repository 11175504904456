import styled from "@emotion/styled";

import { Chip } from "@ag/components/Chip";
import { ActionButton } from "@ag/components/buttons";
import { P2Typography } from "@ag/ui";

type SAddRoundButtonProps = {
  margin?: boolean;
};

export const SAddRoundButton = styled(ActionButton)`
  && {
    padding: 0;

    ${({ margin }: SAddRoundButtonProps): string => {
      let styles = ``;

      if (margin) {
        styles += `margin-top: 16px;`;
      }

      return styles;
    }}
  }
`;

export const SApplicationWrapper = styled.div`
  & + & {
    margin-top: 32px;
  }
`;

export const SApplicationRoundTitle = styled.h5`
  margin: 0;
  line-height: 24px;
  font-size: 12px;
  text-transform: uppercase;
`;

export const SChip = styled(Chip)`
  margin-bottom: 16px;
`;

export const SIntroText = styled.div`
  ${P2Typography};

  ul {
    padding-left: 18px;
    margin-top: 2px;
    margin-bottom: 8px;
  }
`;
