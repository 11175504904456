import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~lib/react-query";

import { FarmFormValues } from "../components/farm-form/farm-form";
import { Farm } from "../entities/farm";
import { farmsKeys } from "./get-farms";

type UpdateFarmForm = FarmFormValues & { id: string };

const updateFarm = async ({ id, name, countryCode }: UpdateFarmForm) => {
  const response = await axios.put<CommonResponse<Farm>>(
    `/api/v1/farms/${id}`,
    {
      name,
      countryCode,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useUpdateFarmMutation = (id: string) =>
  useMutation(
    (data: FarmFormValues) =>
      updateFarm({
        id,
        ...data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(farmsKeys.list());
      },
      onError: error => {
        ToastNotification.error(error);
      },
    },
  );
