import { createRef, forwardRef, useEffect } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn } from "~utils";

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "type" | "dangerouslySetInnerHTML"
> & {
  isInvalid?: boolean;
  isDisabled?: boolean;
};

export const Toggle = forwardRef<HTMLInputElement, Props>(
  ({ checked, isInvalid, isDisabled, className, ...rest }, ref) => {
    const inputRef = createRef<HTMLInputElement>();

    useEffect(() => {
      inputRef.current?.setCustomValidity(isInvalid ? "error" : "");
    }, [inputRef, isInvalid]);

    return (
      <input
        ref={mergeRefs([ref, inputRef])}
        type="checkbox"
        checked={checked}
        disabled={isDisabled}
        className={cn(
          [
            "m-0 inline-grid h-5 w-8 cursor-pointer appearance-none grid-cols-1 items-center gap-2 rounded-[20px] bg-grey-300 !p-0.5 text-white-100 before:block before:h-4 before:w-4 before:rounded-full before:bg-grey-800 before:duration-150 before:ease-out before:content-[''] disabled:cursor-not-allowed disabled:bg-grey-200 disabled:before:bg-grey-700",
            "checked:bg-opal-200 checked:before:translate-x-[calc(100%-4px)] checked:before:bg-green-500",
            "invalid:bg-red-100 invalid:before:bg-red-800",
          ],
          className,
        )}
        {...rest}
      />
    );
  },
);

Toggle.displayName = "Toggle";
