import { useLocation, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useDidUpdate } from "rooks";

import I18n from "@ag/i18n";

import ImgAgreenaLogo from "~assets/images/logos/agreena.svg";

import ImgPest from "../assets/icon-pest.svg";
import { SButton, SContainer, SIcon, SLogo, SMessage, STitle } from "../styled";

type Props = {
  onErrorReset: () => void;
};

const GeneralError = ({ onErrorReset }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const intercom = useIntercom();

  useDidUpdate(() => {
    onErrorReset();
  }, [pathname]);

  const handleMessageClicked = (event: React.MouseEvent) => {
    event.preventDefault();

    // Show intercom dialog if "contact support" link in the message has been clicked
    if ((event.target as HTMLElement).tagName.toLowerCase() === "a") {
      intercom.show();
    }
  };

  return (
    <SContainer>
      <SLogo src={ImgAgreenaLogo} />

      <SIcon src={ImgPest} />

      <STitle>{I18n.t("js.error_boundary.oops")}</STitle>

      <SMessage
        dangerouslySetInnerHTML={{
          __html: I18n.t("js.error_boundary.unexpected_error"),
        }}
      />

      <SButton onClick={() => navigate(-1)}>
        {I18n.t("js.error_boundary.go_back")}
      </SButton>

      <SMessage
        dangerouslySetInnerHTML={{
          __html: I18n.t("js.error_boundary.contact_support"),
        }}
        onClick={handleMessageClicked}
      />
    </SContainer>
  );
};

export default GeneralError;
