import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { AlertBox, AlertBoxType } from "@ag/components/AlertBox";
import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup, FormInput } from "@ag/components/form";
import { P2, SCol } from "@ag/components/styled";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { SFormRow, SFormSubmitButtonContainer } from "~forms/styled";

import {
  ENERGY_WARNING_MAX_AMOUNT,
  ENERGY_WARNING_MIN_AMOUNT,
} from "./constants";
import { FuelConsumptionAttribute, FuelConsumptionV2021Data } from "./types";

type Props = {
  energySource: LabelValue<string>[];
  isReadonly?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<FuelConsumptionV2021Data>) => void;
};

export const FuelConsumptionForm = ({
  energySource,
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<FuelConsumptionV2021Data>();
  const { values, pristine } = useFormState<FuelConsumptionV2021Data>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const energyConsumptionAmountHa = values[FuelConsumptionAttribute.AmountHa];
  let isAmountWarningVisible = false;

  if (
    energyConsumptionAmountHa &&
    (energyConsumptionAmountHa < ENERGY_WARNING_MIN_AMOUNT ||
      energyConsumptionAmountHa > ENERGY_WARNING_MAX_AMOUNT)
  ) {
    isAmountWarningVisible = true;
  }

  return (
    <>
      <SFormRow>
        <SCol>
          <P2>{I18n.t("js.carbon.energy_consumption_description")}</P2>
        </SCol>
      </SFormRow>

      <SFormRow>
        <SCol md={12}>
          <Field
            wrapperTestTag="fuelConsumption-energySourceButtonGroup"
            label={I18n.t("js.carbon.energy_source")}
            hint={I18n.t("js.carbon.energy_source_instruction")}
            name={FuelConsumptionAttribute.EnergySource}
            component={FormButtonsGroup}
            buttons={energySource}
            disabled={isReadonly}
          />
        </SCol>

        <SCol md={12}>
          <Field
            type="number"
            label={I18n.t(
              "js.farmer.carbon.fuel_consumption.number_litres_per_ha",
            )}
            description={I18n.t(
              "js.carbon.energy_consumption_amount_instruction",
            )}
            testTag="fuelConsumption-amountInput"
            name={FuelConsumptionAttribute.AmountHa}
            component={FormInput}
            disabled={isReadonly}
          />

          {isAmountWarningVisible && (
            <AlertBox
              icon={faExclamationTriangle}
              type={AlertBoxType.Warning}
              testTag="fuelConsumption-alertBox"
              closable={false}
            >
              <div>
                <strong>
                  {I18n.t("js.farmer.carbon.energy_consumption_warning_title")}
                </strong>

                <br />

                {I18n.t(
                  "js.farmer.carbon.energy_consumption_warning_description",
                )}
              </div>
            </AlertBox>
          )}
        </SCol>
      </SFormRow>

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          <ActionButton
            HTMLType="submit"
            testTag="fuelConsumption-submitButton"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}
    </>
  );
};
