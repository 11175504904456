import { useQuery } from "react-query";

import { APIResponse } from "@ag/utils/types";

import { CurrentUser } from "~features/authentication";
import { getRequest } from "~lib/axios";

import { UseFeaturesQueryOptions } from "./types";

export const featureKeys = {
  all: ["features"],

  listAll: () => [...featureKeys.all, "list"],
  list: (userId?: string) => [...featureKeys.listAll(), { userId }],
} as const;

/**
 * QUERY FUNCTIONS
 */
const fetchFeatures = async (): Promise<string[]> => {
  const response: APIResponse<string[]> = await getRequest({
    path: "/api/v1/features",
  });

  return response.data.result.data;
};

/**
 * QUERY HOOKS
 */
export const useFeaturesQuery = (
  currentUser: CurrentUser | undefined,
  options?: UseFeaturesQueryOptions,
) =>
  useQuery(featureKeys.list(currentUser?.id), fetchFeatures, {
    ...options,
    staleTime: Infinity,
    enabled: Boolean(currentUser),
  });
