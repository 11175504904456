import styled from "@emotion/styled";

import { P2, SCard } from "@ag/components/styled";
import { style } from "@ag/ui";

export const SDescription = styled(P2)`
  text-align: center;
  margin: 16px 0 8px;
`;

export const SLearnMoreButtonContainer = styled.div`
  text-align: center;
`;

export const SRoot = styled(SCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${style.color.white[100]};
  position: relative;
  padding-top: 0;
`;
