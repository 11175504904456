import React from "react";

import AgreenaCarbonLogoGreen from "./assets/agreena-carbon-logo-green.svg";
import AgreenaCarbonLogoWhite from "./assets/agreena-carbon-logo-white.svg";
import AgreenaLogoGreen from "./assets/agreena-logo-green.svg";
import AgreenaLogoWhite from "./assets/agreena-logo-white.svg";

const logos = {
  carbon: {
    green: AgreenaCarbonLogoGreen,
    white: AgreenaCarbonLogoWhite,
  },
  default: {
    green: AgreenaLogoGreen,
    white: AgreenaLogoWhite,
  },
};

type Props = {
  variant?: "carbon" | "default";
  color?: "green" | "white";
} & React.HTMLAttributes<SVGElement>;

export const Logo = ({
  variant = "default",
  color = "green",
  ...rest
}: Props) => {
  const LogoComponent = logos[variant][color];

  return <LogoComponent {...rest} />;
};
