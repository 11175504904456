import { css } from "@emotion/core";

import { style } from "@ag/ui";

export const intercomStyles = css`
  .intercom-launcher {
    && {
      background: ${style.color.green[300]};
    }
  }
`;
