import { SModalTitle } from "@ag/components/Modal";
import I18n from "@ag/i18n";

import { ModalContentProps } from "~components/GlobalModal";

import { SWrapper } from "../styled";
import FieldValidationManagement from "./FieldValidationManagement";

export type Extras = {
  harvestYear: number;
};

const CreateValidationRequestForAnotherYear = ({
  extras,
  onClose,
}: ModalContentProps) => {
  const harvestYear = Number((extras as Extras)?.harvestYear);

  return (
    <SWrapper className="carbon">
      <SModalTitle>{`${I18n.t(
        "js.carbon.apply_for_validation",
      )} ${harvestYear}`}</SModalTitle>

      <FieldValidationManagement harvestYear={harvestYear} onClose={onClose} />
    </SWrapper>
  );
};

export default CreateValidationRequestForAnotherYear;
