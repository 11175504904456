import { useAtom } from "jotai";
import { useFormContext } from "react-hook-form";

import { InputField, setInputValueAsNumber } from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";

import { isDisabledAtom } from "../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../schemas/field-definition-form";

const LandRightsFormSection = () => {
  const [isDisabled] = useAtom(isDisabledAtom);
  const { register, formState } = useFormContext<FieldDefinitionData>();

  const errors = formState.errors.verraQuestionnaire;

  return (
    <FormSection.Root
      title={I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.land_rights.title",
      )}
      description={I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.land_rights.description",
      )}
    >
      <FormSection.Card>
        <InputField
          {...register("verraQuestionnaire.hectaresLeased", {
            setValueAs: setInputValueAsNumber,
          })}
          label={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.land_rights.hectares_leased",
          )}
          tooltip={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.land_rights.tip",
          )}
          placeholder={I18n.t("js.shared.enter")}
          step=".01"
          type="number"
          rightAddon={I18n.t("js.shared.hectares_unit")}
          data-test="hectares-leased"
          isRequired
          isDisabled={isDisabled}
          error={errors?.hectaresLeased}
          onWheel={e => e.currentTarget.blur()}
        />
      </FormSection.Card>

      <FormSection.Card>
        <InputField
          {...register("verraQuestionnaire.hectaresLostInNextFiveYears", {
            setValueAs: setInputValueAsNumber,
          })}
          label={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.land_rights.hactares_lost",
          )}
          tooltip={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.land_rights.tip",
          )}
          placeholder={I18n.t("js.shared.enter")}
          step=".01"
          type="number"
          rightAddon={I18n.t("js.shared.hectares_unit")}
          data-test="hectares-lost-in-next-five-years"
          isRequired
          error={errors?.hectaresLostInNextFiveYears}
          isDisabled={isDisabled}
          onWheel={e => e.currentTarget.blur()}
        />
      </FormSection.Card>
    </FormSection.Root>
  );
};

export default LandRightsFormSection;
