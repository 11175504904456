import { z } from "zod";

import { FuelConsumptionV2021DataSchema } from "./schemas";

export enum FuelConsumptionAttribute {
  EnergySource = "energyConsumptionEnergySource",
  AmountHa = "energyConsumptionAmountHa",
  AmountUnit = "energyConsumptionAmountUnit",
}

export enum FuelConsumptionOptionalAttribute {
  AmountUnit = FuelConsumptionAttribute.AmountUnit,
}

export type FuelConsumptionV2021Data = z.infer<
  typeof FuelConsumptionV2021DataSchema
>;
