import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~lib/react-query";

import { farmsKeys } from "./get-farms";

const deleteFarm = async (farmId: string) => {
  await axios.delete(`/api/v1/farms/${farmId}`, {
    apiSource: "node-carbon",
  });
};

export const useDeleteFarmMutation = () => {
  return useMutation(deleteFarm, {
    onSuccess: () => {
      queryClient.invalidateQueries(farmsKeys.list());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
};
