import type { Config } from "tailwindcss";
import animatePlugin from "tailwindcss-animate";
import plugin from "tailwindcss/plugin";

import { colorsObject } from "../tokens/colors";
import keyframes from "./theme/keyframes";

// KEEP THIS FILE IN SYNC with extendedTWMerge for custom css classes that follow a similar pattern to tailwinds classes
export const tailwindConfig = {
  content: [],
  theme: {
    extend: {
      zIndex: {
        low: "1",
        banner: "1000",
        drawer: "2000",
        modal: "3000",
        notifications: "4000",
      },
    },
    colors: {
      ...colorsObject,
      transparent: "transparent",
      current: "currentColor",
      positive: {
        foreground: colorsObject.green[500],
        background: colorsObject.opal[100],
        hover: colorsObject.opal[300],
        border: colorsObject.opal[100],
      },
      warning: {
        foreground: colorsObject.orange[900],
        background: colorsObject.orange[100],
        hover: colorsObject.orange[200],
        border: colorsObject.orange[100],
      },
      info: {
        foreground: colorsObject.blue[800],
        background: colorsObject.blue[100],
        hover: colorsObject.blue[200],
        border: colorsObject.blue[100],
      },
      danger: {
        foreground: colorsObject.red[800],
        background: colorsObject.red[100],
        hover: colorsObject.red[200],
        border: colorsObject.red[100],
      },
      neutral: {
        foreground: colorsObject.grey[800],
        background: colorsObject.grey[300],
        hover: colorsObject.grey[400],
        border: colorsObject.grey[300],
      },
    },
    fontSize: {
      h1: [
        "2rem", // 32px
        {
          lineHeight: "3rem", // 48px
          fontWeight: "700",
        },
      ],
      h2: [
        "1.5rem", // 24px
        {
          lineHeight: "2.25rem", // 36px
          fontWeight: "700",
        },
      ],
      h3: [
        "1.25rem", // 20px
        {
          lineHeight: "1.875rem", // 30px
          fontWeight: "700",
        },
      ],
      h4: [
        "1rem", // 16px
        {
          lineHeight: "1.5rem", // 24px
          fontWeight: "700",
        },
      ],
      h5: [
        "0.875rem", // 14px
        {
          lineHeight: "1.25rem", // 20px
          fontWeight: "700",
        },
      ],
      h6: [
        "0.75rem", // 12px
        {
          lineHeight: "1.25rem", // 20px
          fontWeight: "700",
        },
      ],
      h7: [
        "0.6875rem", // 11px
        {
          lineHeight: "1rem", // 16px
          fontWeight: "700",
        },
      ],
      h8: [
        "0.625rem", // 10px
        {
          lineHeight: "0.875rem", // 14px
          fontWeight: "700",
        },
      ],
      p1: [
        "1rem", // 16px
        {
          lineHeight: "1.5rem", // 24px
          fontWeight: "400",
        },
      ],
      p2: [
        "0.875rem", // 14px
        {
          lineHeight: "1.25rem", // 20px
          fontWeight: "400",
        },
      ],
      p3: [
        "0.75rem", // 12px
        {
          lineHeight: "1.25rem", // 20px
          fontWeight: "400",
        },
      ],
      input: [
        "0.875rem", // 14px
        {
          lineHeight: "1.25rem", // 20px
          fontWeight: "600",
        },
      ],
      inherit: "inherit",
    },
    lineHeight: {
      h1: "3rem", // 48px
      h2: "2.25rem", // 36px
      h3: "1.875rem", // 30px
      h4: "1.5rem", // 24px
      h5: "1.25rem", // 20px
      h6: "1.25rem", // 20px
      h7: "1rem", // 16px
      h8: "0.875rem", // 14px
      p1: "1.5rem", // 24px
      p2: "1.25rem", // 20px
      p3: "1.25rem", // 20px
      input: "1.25rem", // 20px
    },
    fontWeight: {
      h1: "700",
      h2: "700",
      h3: "700",
      h4: "700",
      h5: "700",
      h6: "700",
      h7: "700",
      h8: "700",
      p1: "400",
      p2: "400",
      p3: "400",
      input: "600",
    },

    boxShadow: {
      100: "0px 4px 8px rgba(0, 0, 0, 0.05)",
      200: "0px 8px 8px rgba(0, 0, 0, 0.1)",
    },

    keyframes,
  },
  plugins: [
    animatePlugin,
    plugin(function ({ addVariant }) {
      addVariant("group-state-open", ":merge(.group)[data-state=open] &");
    }),
  ],
} satisfies Config;
