import isNil from "lodash/isNil";
import { Field, useField } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup, FormInput, FormSelect } from "@ag/components/form";
import { SFlexDiv } from "@ag/components/styled";
import I18n from "@ag/i18n";
import { parseInputNumber } from "@ag/utils/helpers";
import { FieldStep } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";
import { FertiliserEmissionsInhibitors, FertiliserMode } from "~types";

import { EMPTY_FERTILISER } from "../constants";
import {
  getEmissionsInhibitorsOptions,
  getFertiliserTypeOptions,
} from "../helpers";
import {
  FertiliserData,
  FertiliserDataAttribute,
  FertiliserViewAttribute,
} from "../types";
import {
  SFertiliser,
  SFertiliserTitle,
  SLargeCol,
  SMediumCol,
  SRow,
  SSmallCol,
} from "./styled";

type Props = {
  fields: any;
  props: FieldArrayRenderProps<FertiliserData, HTMLElement>;
  step: FieldStep.Actuals;
  fertilisers: ISOFertiliser[] | undefined;
  index: number;
  name: string;
  isReadonly: boolean;
};

const FertiliserRound = ({
  name,
  index,
  fields,
  fertilisers,
  step,
  props,
  isReadonly,
}: Props) => {
  const isFertilisersUsedField = useField<boolean>(
    FertiliserViewAttribute.IsFertilisersUsed,
  );

  const fertiliser: FertiliserData = fields.value[index];

  const isEmissionsInhibitorsVisible =
    fertiliser.mode === FertiliserMode.Organic ||
    (fertiliser.mode === FertiliserMode.Synthetic &&
      fertiliser.nitrogenApplicationRate);

  const handleRemoveFertiliser = (
    fieldArrayProps: FieldArrayRenderProps<FertiliserData, HTMLElement>,
    index: number,
  ) => {
    const numberOfFertilisers = fieldArrayProps.fields.value.length;

    fieldArrayProps.fields.remove(index);

    if (numberOfFertilisers === 1) {
      isFertilisersUsedField.input.onChange(false);
    }
  };

  // Reset fertiliser when the mode is changed
  const handleFertiliserModeChanged = (mode: FertiliserMode) => {
    fields.update(index, {
      ...EMPTY_FERTILISER,
      mode,
    });
  };

  // Reset nitrification rate if there is no nitrification added
  const handleNitrificationAddedChanged = (
    emissionsInhibitors: FertiliserEmissionsInhibitors,
  ) => {
    if (emissionsInhibitors === FertiliserEmissionsInhibitors.None) {
      fields.update(index, {
        ...fertiliser,
        emissionsInhibitors,
        nitrificationApplicationRate: null,
      });
    }
  };

  const handleNitrogenApplicationRateChanged = (value: string) => {
    if (!value && fertiliser.mode === FertiliserMode.Synthetic) {
      fields.update(index, {
        ...fertiliser,
        nitrogenApplicationRate: parseInputNumber(value),
        nitrificationApplicationRate: null,
        emissionsInhibitors: null,
      });
    }
  };

  const handlePhosphorusApplicationRateChanged = (value: string) => {
    if (!value && fertiliser.mode === FertiliserMode.Synthetic) {
      fields.update(index, {
        ...fertiliser,
        phosphorusApplicationRate: parseInputNumber(value),
        phosphorusType: null,
      });
    }
  };

  const handlePotassiumApplicationRateChanged = (value: string) => {
    if (!value && fertiliser.mode === FertiliserMode.Synthetic) {
      fields.update(index, {
        ...fertiliser,
        potassiumApplicationRate: parseInputNumber(value),
        potassiumType: null,
      });
    }
  };

  const fertiliserTooltipByStep = {
    [FieldStep.Actuals]: I18n.t(
      "js.carbon.field.fertilisers.actuals.nitrogen_fertiliser_tooltip",
    ),
  };
  const fertiliserTooltip = fertiliserTooltipByStep[step];

  return (
    <SFertiliser key={name}>
      <SFlexDiv justifyContent="space-between" alignItems="center">
        <SFertiliserTitle>
          {I18n.t("js.carbon.fertiliser_title")} {index + 1}
        </SFertiliserTitle>

        {!isReadonly && (
          <ActionButton
            type="secondary"
            variant="danger"
            size="extra-small"
            onClick={() => handleRemoveFertiliser(props, index)}
          >
            {I18n.t("js.shared.delete")}
          </ActionButton>
        )}
      </SFlexDiv>

      <SRow>
        <Field
          name={`${name}.${FertiliserDataAttribute.Mode}`}
          label={I18n.t("js.carbon.field.fertilisers.type_of_nitrogen")}
          component={FormButtonsGroup}
          buttons={getFertiliserTypeOptions()}
          disabled={isReadonly}
          onChangeCustom={handleFertiliserModeChanged}
        />
      </SRow>

      {fertiliser.mode === FertiliserMode.Organic && (
        <SRow>
          <SLargeCol>
            <Field
              name={`${name}.${FertiliserDataAttribute.CarbonFertiliserId}`}
              label={I18n.t("js.carbon.field.fertilisers.nitrogen_fertiliser")}
              component={FormSelect}
              options={fertilisers?.map(fertiliser => ({
                label: fertiliser.name,
                value: fertiliser.id,
              }))}
              tooltip={fertiliserTooltip}
              testTag="fertilisersPartial-fertiliserId"
              isDisabled={isReadonly}
              isSearchable
            />
          </SLargeCol>

          <SSmallCol style={{ marginLeft: 16 }}>
            <Field
              name={`${name}.${FertiliserDataAttribute.ApplicationRate}`}
              label={I18n.t(
                "js.carbon.field.fertilisers.fertiliser_application_rate",
              )}
              component={FormInput}
              type="number"
              rightElement={I18n.t("js.carbon.kg_ha")}
              parse={parseInputNumber}
              disabled={isReadonly}
            />
          </SSmallCol>
        </SRow>
      )}

      {fertiliser.mode === FertiliserMode.Synthetic && (
        <SRow>
          <SSmallCol>
            <Field
              name={`${name}.${FertiliserDataAttribute.NitrogenApplicationRate}`}
              label={I18n.t(
                "js.carbon.field.fertilisers.nitrogen_application_rate",
              )}
              component={FormInput}
              type="number"
              rightElement={I18n.t("js.carbon.kg_ha")}
              parse={parseInputNumber}
              disabled={isReadonly}
              onChangeCustom={handleNitrogenApplicationRateChanged}
            />
          </SSmallCol>
        </SRow>
      )}

      {isEmissionsInhibitorsVisible && (
        <SRow>
          <Field
            name={`${name}.${FertiliserDataAttribute.EmissionsInhibitors}`}
            label={I18n.t("js.carbon.field.fertilisers.apply_nitrification")}
            component={FormButtonsGroup}
            buttons={getEmissionsInhibitorsOptions()}
            disabled={isReadonly}
            onChangeCustom={handleNitrificationAddedChanged}
          />
        </SRow>
      )}

      {fertiliser.emissionsInhibitors ===
        FertiliserEmissionsInhibitors.Nitrification && (
        <SRow>
          <SMediumCol>
            <Field
              name={`${name}.${FertiliserDataAttribute.NitrificationApplicationRate}`}
              label={I18n.t(
                "js.carbon.field.fertilisers.how_much_fertiliser_on",
              )}
              component={FormInput}
              type="number"
              rightElement={I18n.t("js.carbon.kg_ha")}
              parse={parseInputNumber}
              disabled={isReadonly}
            />
          </SMediumCol>
        </SRow>
      )}

      {fertiliser.mode === FertiliserMode.Synthetic && (
        <SRow>
          <SSmallCol>
            <Field
              name={`${name}.${FertiliserDataAttribute.PhosphorusApplicationRate}`}
              label={I18n.t("js.carbon.field.fertilisers.p_application_rate")}
              component={FormInput}
              type="number"
              rightElement={I18n.t("js.carbon.kg_ha")}
              tooltip={I18n.t("js.carbon.field.fertilisers.tooltip.p")}
              parse={parseInputNumber}
              disabled={isReadonly}
              onChangeCustom={handlePhosphorusApplicationRateChanged}
            />
          </SSmallCol>

          {!isNil(fertiliser.phosphorusApplicationRate) && (
            <div style={{ marginLeft: 16, marginTop: 24, flex: 0 }}>
              <Field
                name={`${name}.${FertiliserDataAttribute.PhosphorusType}`}
                component={FormButtonsGroup}
                buttons={[
                  { label: "P", value: "P" },
                  { label: "P2O5", value: "P2O5" },
                ]}
                disabled={!fertiliser.phosphorusApplicationRate || isReadonly}
              />
            </div>
          )}

          <SSmallCol style={{ marginLeft: 24 }}>
            <Field
              name={`${name}.${FertiliserDataAttribute.PotassiumApplicationRate}`}
              label={I18n.t("js.carbon.field.fertilisers.k_application_rate")}
              component={FormInput}
              type="number"
              rightElement={I18n.t("js.carbon.kg_ha")}
              tooltip={I18n.t("js.carbon.field.fertilisers.tooltip.k")}
              parse={parseInputNumber}
              disabled={isReadonly}
              onChangeCustom={handlePotassiumApplicationRateChanged}
            />
          </SSmallCol>

          {!isNil(fertiliser.potassiumApplicationRate) && (
            <div style={{ marginLeft: 16, marginTop: 24, flex: 0 }}>
              <Field
                name={`${name}.${FertiliserDataAttribute.PotassiumType}`}
                component={FormButtonsGroup}
                buttons={[
                  { label: "K", value: "K" },
                  { label: "K2O", value: "K2O" },
                ]}
                disabled={!fertiliser.potassiumApplicationRate || isReadonly}
              />
            </div>
          )}
        </SRow>
      )}
    </SFertiliser>
  );
};

export default FertiliserRound;
