import { css } from "@emotion/core";

import { P3Typography, style } from "@ag/ui";

// TODO: refactor usages and remove
export const reactTooltipStyles = css`
  .ct-tooltip {
    && {
      ${P3Typography}

      max-width: 304px;
      z-index: 9999;
      background-color: ${style.color.green[400]};
    }

    &&& {
      &.place-top::after {
        border-top-color: ${style.color.green[400]};
      }

      &.place-bottom::after {
        border-bottom-color: ${style.color.green[400]};
      }

      &.place-left::after {
        border-left-color: ${style.color.green[400]};
      }

      &.place-right::after {
        border-right-color: ${style.color.green[400]};
      }
    }

    &.wide {
      max-width: 400px;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: 20px;
    }

    & > .multi-line {
      text-align: left !important;
    }
  }
`;
