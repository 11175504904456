import isEmpty from "lodash/isEmpty";
import { useState } from "react";

import { SModalTitle } from "@ag/components/Modal";
import { ActionButton } from "@ag/components/buttons";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import { ModalContentProps } from "~components/GlobalModal";
import { SAddFieldsContent } from "~components/GlobalModal/modals/validation-flow/styled";
import { queryClient } from "~lib/react-query";
import { fieldsKeys } from "~queries/carbon/fields";
import {
  ValidationRequestStatus,
  useValidationRequestsQuery,
} from "~queries/carbon/validation-requests";
import { useUpdateValidationRequestMutation } from "~queries/carbon/validation-requests/mutations";

import FieldsForValidation from "../components/FieldsForValidation";
import { SCancelButton, SDescription, SStepFooter, SWrapper } from "../styled";
import ShowValidatedFields from "./components/ShowValidatedFields";

export type Extras = {
  harvestYear: number;
};

const AddMoreFieldsToValidationRequest = ({
  extras,
  onClose,
}: ModalContentProps) => {
  const [carbonFieldIds, setCarbonFieldIds] = useState<number[] | null>(null);

  const harvestYear = Number((extras as Extras)?.harvestYear);

  const updateValidationRequestMutation = useUpdateValidationRequestMutation();
  const { data: validationRequests } = useValidationRequestsQuery();

  const handleAddMoreFieldsClicked = () => {
    if (isEmpty(carbonFieldIds)) {
      ToastNotification.warning(
        I18n.t("js.carbon.please_choose_fields_to_validate"),
      );

      return;
    }

    const pendingValidationRequest = validationRequests?.find(
      validationRequest =>
        validationRequest.status === ValidationRequestStatus.New &&
        validationRequest.harvestYear === harvestYear,
    );

    if (carbonFieldIds && pendingValidationRequest && onClose) {
      updateValidationRequestMutation.mutate(
        {
          requestId: pendingValidationRequest.id,
          carbonFieldIds: carbonFieldIds.map(String),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(fieldsKeys.fieldsAll());

            onClose();

            ToastNotification.success(
              I18n.t("js.carbon.validation_application_has_been_updated"),
            );
          },
        },
      );
    }
  };

  return (
    <SWrapper className="carbon">
      <SModalTitle>{I18n.t("js.carbon.add_more_fields")}</SModalTitle>

      <SDescription
        dangerouslySetInnerHTML={{
          __html: I18n.t("js.carbon.about_to_select_fields_for_validation"),
        }}
      />

      <SAddFieldsContent>
        <ShowValidatedFields />

        <FieldsForValidation
          harvestYear={harvestYear}
          onChosenFieldsUpdate={fieldsToBeSubmitted => {
            const fieldIdsAddedToValidate = Object.keys(fieldsToBeSubmitted)
              .filter((key: string) => fieldsToBeSubmitted[Number(key)])
              .map(Number);

            setCarbonFieldIds(fieldIdsAddedToValidate);
          }}
        />
      </SAddFieldsContent>

      <SStepFooter>
        <SCancelButton type="text" onClick={onClose}>
          {I18n.t("js.shared.cancel")}
        </SCancelButton>

        <ActionButton
          isLoading={updateValidationRequestMutation.isLoading}
          onClick={handleAddMoreFieldsClicked}
        >
          {I18n.t("js.carbon.add_more_fields")}
        </ActionButton>
      </SStepFooter>
    </SWrapper>
  );
};

export default AddMoreFieldsToValidationRequest;
