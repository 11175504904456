import styled from "@emotion/styled";

import { SRow } from "@ag/components/styled";
import { colors, radius } from "@ag/design-system/tokens";
import { media } from "@ag/ui";

type SFormRowProps = {
  margin?: boolean;
};

export const SFormRow = styled(SRow)`
  & + & {
    margin-top: 16px;
  }

  ${({ margin }: SFormRowProps): string => {
    let styles = ``;

    if (margin) {
      styles = `margin-bottom: 16px`;
    }

    return styles;
  }}
`;

export const SFormSubmitButtonContainer = styled(SFormRow)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 24px;

  ${media.sm`
    flex-direction: column;

    button + button {
      margin-top: 8px;
    }
  `}
`;

export const SGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  padding: 16px;

  border: 1px solid ${colors.grey[300]};
  border-radius: ${radius[100]};
`;
