import debounce from "debounce-promise";
import { useMemo } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { AlertBoxType } from "@ag/components/AlertBox";
import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup, FormInput, FormSelect } from "@ag/components/form";
import { SCol, SFlexDiv, SPartialForm, SRow } from "@ag/components/styled";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";
import { SFormSubmitButtonContainer } from "~forms/styled";
import { FertiliserAddingMode } from "~types";

import { FILTER_FERTILISER_TYPES_DEBOUNCE_TIME } from "../../constants";
import {
  filterFertiliserTypesByName,
  generateEmissionInhibitorOptions,
  mapFertiliserTypeToOptions,
} from "../../helpers";
import {
  SAddRoundButton,
  SApplicationRoundTitle,
  SApplicationWrapper,
} from "../../styled";
import {
  FertiliserAttribute,
  FertiliserDetailsAttribute,
  FertiliserDetailsData,
} from "../../types";
import {
  SAlert,
  SSeparator,
  SSeparatorSection,
  SSeparatorText,
} from "../styled";

type Props = {
  fertilisers: ISOFertiliser[];
  applicationModes: LabelValue<string>[];
  hasApplications: boolean;
  isReadonly: boolean | undefined;
  isSubmitting: boolean | undefined;
  onAddingModeButtonClick: (addingMode: FertiliserAddingMode) => void;
};

const FertiliserList = ({
  applicationModes,
  fertilisers,
  hasApplications,
  isReadonly,
  isSubmitting,
  onAddingModeButtonClick,
}: Props) => {
  const { initialValues, pristine } = useFormState();
  const { submit } = useForm();

  const isSubmissionOverwriting =
    initialValues[FertiliserAttribute.FertilisersShowChemicalSpecification] ===
    true;

  const fertiliserTypeOptions = useMemo(
    () => mapFertiliserTypeToOptions(fertilisers),
    [fertilisers],
  );

  const handleAddApplicationClicked = (fields: {
    push: (value: FertiliserDetailsData) => void;
  }) => {
    const emptyFertiliserData = {
      [FertiliserDetailsAttribute.Id]: null,
      [FertiliserDetailsAttribute.ApplicationMethod]: null,
      [FertiliserDetailsAttribute.EmissionsInhibitors]: null,
      [FertiliserDetailsAttribute.ApplicationRate]: null,
    };

    fields.push(emptyFertiliserData);
  };

  const handleOptionsLoadDebounced = debounce(
    (searchText: string) =>
      filterFertiliserTypesByName(fertiliserTypeOptions, searchText),
    FILTER_FERTILISER_TYPES_DEBOUNCE_TIME,
  );

  return (
    <SPartialForm>
      <FieldArray name={FertiliserAttribute.Fertilisers}>
        {({ fields }): React.ReactNode => (
          <div>
            {fields.map((name, index) => {
              const attributesGroup = fields.value[index];
              const selectedFertiliserId =
                attributesGroup &&
                attributesGroup[FertiliserDetailsAttribute.Id];
              const selectedFertiliser = fertilisers
                ? fertilisers[selectedFertiliserId]
                : null;
              const emissionsInhibitorsOptions =
                generateEmissionInhibitorOptions(selectedFertiliser);
              const hasAnyEmissionInhibitorsOptionAvailable =
                emissionsInhibitorsOptions.some(
                  (option: LabelValue<string>) => !option.disabled,
                );
              const isCarbonNitrificationVisible = !(
                !selectedFertiliserId ||
                !hasAnyEmissionInhibitorsOptionAvailable
              );

              return (
                <SApplicationWrapper key={name}>
                  <SFlexDiv
                    style={{ marginBottom: "2px" }}
                    justifyContent="space-between"
                  >
                    <SApplicationRoundTitle
                      data-test={`fertiliser-applicationRoundTitle-${index}`}
                    >
                      {I18n.t("js.carbon.application_round")} {index + 1}
                    </SApplicationRoundTitle>

                    {!isReadonly && (
                      <ActionButton
                        type="secondary"
                        variant="danger"
                        size="extra-small"
                        testTag={`fertiliser-deleteBtn-${index}`}
                        onClick={() => {
                          fields.remove(index);
                        }}
                      >
                        {I18n.t("js.shared.delete")}
                      </ActionButton>
                    )}
                  </SFlexDiv>

                  <SRow>
                    <SCol col={6} md={6}>
                      <Field
                        name={`${name}.${FertiliserDetailsAttribute.Id}`}
                        label={I18n.t("js.carbon.fertiliser_type")}
                        testTag={`fertiliser-idSelect-${index}`}
                        component={FormSelect}
                        disabled={isReadonly}
                        async
                        options={fertiliserTypeOptions}
                        onLoadOptions={handleOptionsLoadDebounced}
                        onChangeCustom={() => {
                          fields.update(index, {});
                        }}
                      />
                    </SCol>

                    {isCarbonNitrificationVisible && (
                      <SCol col={6} md={6}>
                        <Field
                          name={`${name}.${FertiliserDetailsAttribute.EmissionsInhibitors}`}
                          label={I18n.t("js.carbon.nitrification")}
                          wrapperTestTag={`fertiliser-emissionInhibitorsBtnGroup-${index}`}
                          component={FormButtonsGroup}
                          buttons={emissionsInhibitorsOptions}
                          tooltip={I18n.t("js.carbon.nitrification_tooltip")}
                          disabled={isReadonly}
                        />
                      </SCol>
                    )}
                  </SRow>

                  <SRow>
                    <SCol col={4} md={12}>
                      <Field
                        name={`${name}.${FertiliserDetailsAttribute.ApplicationRate}`}
                        label={I18n.t("js.carbon.application_rate")}
                        testTag={`fertiliser-applicationRateInput-${index}`}
                        component={FormInput}
                        type="number"
                        tooltip={I18n.t("js.carbon.application_rate_tooltip")}
                        disabled={isReadonly}
                      />
                    </SCol>
                  </SRow>

                  <SRow>
                    <SCol md={12}>
                      <Field
                        name={`${name}.${FertiliserDetailsAttribute.ApplicationMethod}`}
                        label={I18n.t("js.carbon.application_method")}
                        wrapperTestTag={`fertiliser-applicationMethodButtonGroup-${index}`}
                        component={FormButtonsGroup}
                        buttons={applicationModes}
                        hint={I18n.t("js.carbon.application_method_hint")}
                        disabled={!selectedFertiliserId || isReadonly}
                      />
                    </SCol>
                  </SRow>
                </SApplicationWrapper>
              );
            })}

            {!isReadonly && (
              <SAddRoundButton
                testTag="fertiliser-addApplicationRoundButton"
                type="text"
                margin={fields?.value?.length > 0}
                onClick={() => {
                  handleAddApplicationClicked(fields);
                }}
              >
                + {I18n.t("js.carbon.add_application_round")}
              </SAddRoundButton>
            )}
          </div>
        )}
      </FieldArray>

      {isSubmissionOverwriting && (
        <SAlert type={AlertBoxType.Warning}>
          {I18n.t("js.carbon.saving_list_overwrite_specification")}
        </SAlert>
      )}

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          {!hasApplications && (
            <ActionButton
              HTMLType="submit"
              type="text"
              testTag="fertiliser-notUsingFertiliserBtn"
              isLoading={isSubmitting}
              onClick={submit}
            >
              {I18n.t("js.carbon.not_using_fertiliser")}
            </ActionButton>
          )}

          <ActionButton
            HTMLType="submit"
            testTag="fertiliser-submitButton"
            isLoading={hasApplications && isSubmitting}
            disabled={!hasApplications || pristine}
            onClick={submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}

      {!isReadonly && (
        <>
          <SSeparatorSection>
            <SSeparator />

            <SSeparatorText>{I18n.t("js.shared.or")}</SSeparatorText>

            <SSeparator />
          </SSeparatorSection>

          <ActionButton
            type="secondary"
            onClick={(): void => {
              onAddingModeButtonClick(
                FertiliserAddingMode.ChemicalSpecification,
              );
            }}
          >
            {I18n.t("js.carbon.enter_chemical_specifications")}
          </ActionButton>
        </>
      )}
    </SPartialForm>
  );
};

export default FertiliserList;
