import { formatDuration } from "date-fns";
import { useEffect, useState } from "react";
import { useIntervalWhen } from "rooks";

import I18n from "@ag/i18n";

import ImgAgreenaLogo from "~assets/images/logos/agreena.svg";

import ImgRocket from "../assets/icon-pest.svg";
import {
  SContainer,
  SIcon,
  SLogo,
  SMessage,
  SSeconds,
  STitle,
} from "../styled";

const ChunkLoadError = () => {
  const [secondsToRefresh, setSecondsToRefresh] = useState(10);

  useEffect(() => {
    if (secondsToRefresh === 1) {
      window.location.reload();
    }
  }, [secondsToRefresh]);

  // Show error page for 10 seconds and reload the page
  useIntervalWhen(
    () => {
      setSecondsToRefresh(prevState => prevState - 1);
    },
    1000, // run every second
    secondsToRefresh > 1, // run only if the number of seconds is greater than 1
  );

  return (
    <SContainer>
      <SLogo src={ImgAgreenaLogo} />

      <SIcon src={ImgRocket} />

      <STitle>{I18n.t("js.error_boundary.we_are_getting_better")}</STitle>

      <SMessage>
        {I18n.t("js.error_boundary.refresh_the_page")}

        <SSeconds>
          {formatDuration({
            seconds: secondsToRefresh,
          })}
          ...
        </SSeconds>
      </SMessage>

      <SMessage>{I18n.t("js.error_boundary.thank_you_for_patience")}</SMessage>
    </SContainer>
  );
};

export default ChunkLoadError;
