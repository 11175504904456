import { cva } from "class-variance-authority";

import { Icon } from "~assets";
import { cn } from "~utils";

import { ICONS_REGULAR } from "../../assets/Icon";

export type AssistiveElementVariant =
  | "success"
  | "warning"
  | "neutral"
  | "info"
  | "danger";

export type AssistiveElementProps = React.PropsWithChildrenRequired<{
  variant?: AssistiveElementVariant;
  className?: string;
  isIconVisible?: boolean;
}>;

export const AssistiveElement = ({
  variant = "neutral",
  children,
  className,
  isIconVisible = true,
}: AssistiveElementProps) => {
  const icon: Record<AssistiveElementVariant, keyof typeof ICONS_REGULAR> = {
    success: "check",
    warning: "circle-exclamation",
    neutral: "circle-info",
    info: "circle-info",
    danger: "triangle-exclamation",
  };

  const assistiveElementVariants = cva(undefined, {
    variants: {
      variant: {
        success: "text-green-500",
        warning: "text-orange-700",
        neutral: "text-grey-800",
        info: "text-blue-800",
        danger: "text-red-800",
      },
    },
  });

  return (
    <span
      className={cn(
        "inline-flex items-center gap-1 text-h7 font-input text-grey-600",
        assistiveElementVariants({ variant, className }),
      )}
    >
      {isIconVisible && (
        <Icon
          className={cn("text-[16px]", {
            "mt-[-1px]": variant === "danger",
          })}
          name={icon[variant]}
        />
      )}

      {children}
    </span>
  );
};
