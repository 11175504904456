import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { PageLinkButton } from "@ag/components/buttons";
import I18n from "@ag/i18n";

import Image404 from "./assets/404.svg";
import { SContent, SImage, SPage404 } from "./styled";

type Props = {
  buttonLink: string;
  buttonText?: string;
};

const Page404 = ({ buttonLink, buttonText }: Props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    Sentry.captureMessage(`Route not found: ${pathname}`, "warning");
  }, [pathname]);

  return (
    <SPage404>
      <SImage src={Image404} />

      <SContent>{I18n.t("js.page.not_found.content")}</SContent>

      <PageLinkButton to={buttonLink}>
        {buttonText || I18n.t("js.page.not_found.button")}
      </PageLinkButton>
    </SPage404>
  );
};

export default Page404;
