import React from "react";
import { FieldError } from "react-hook-form";

import {
  ButtonSelect,
  type ButtonSelectValue,
} from "@ag/design-system/molecules";
import { FormField } from "@ag/design-system/organisms";

type Props = {
  label: string;
  value: ButtonSelectValue | undefined;
  children: React.ReactElement[];
  testId?: string;
  tooltip?: string;
  error?: FieldError;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange: (value: ButtonSelectValue | undefined) => void;
  onTooltipHover?: () => void;
};

export const ButtonSelectField = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      value,
      tooltip,
      onTooltipHover,
      testId,
      children,
      error,
      isDisabled,
      isRequired,
      onChange,
    },
    ref,
  ) => {
    return (
      <FormField.Root>
        <FormField.Label tooltip={tooltip} onTooltipHover={onTooltipHover}>
          {label}
          {isRequired && " *"}
        </FormField.Label>

        <ButtonSelect.Root
          ref={ref}
          value={value}
          testId={testId}
          isInvalid={Boolean(error)}
          isDisabled={isDisabled}
          onChange={onChange}
        >
          {children}
        </ButtonSelect.Root>

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

ButtonSelectField.displayName = "ButtonSelectField";
