import { useAtom } from "jotai";
import { useFormContext } from "react-hook-form";

import { InputField, setInputValueAsNumber } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { isDisabledAtom } from "../../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../../schemas/field-definition-form";
import { FertiliserErrors } from "../../types/fertiliser-type";
import { YearKey } from "../crop-form-section/crop-form-section";

const OrganicFertiliserFormQuestions = ({
  index,
  yearKey,
}: {
  index: number;
  yearKey: YearKey;
}) => {
  const [isDisabled] = useAtom(isDisabledAtom);
  const { register, formState } = useFormContext<FieldDefinitionData>();

  const errors =
    (formState.errors.verraQuestionnaire?.[yearKey]?.fertilisers?.[
      index
    ] as FertiliserErrors) ?? {};

  return (
    <>
      <div style={{ width: "100%", maxWidth: 221 }}>
        <InputField
          {...register(
            `verraQuestionnaire.${yearKey}.fertilisers.${index}.organicAmount`,
            { setValueAs: setInputValueAsNumber },
          )}
          type="number"
          step=".01"
          label={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertilisers.organic_amount.title",
          )}
          rightAddon="t/ha"
          placeholder={I18n.t("js.shared.enter")}
          error={errors?.organicAmount}
          description={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertilisers.organic_amount.hint",
          )}
          data-test={`organic-amount-${index}`}
          isDisabled={isDisabled}
          onWheel={e => e.currentTarget.blur()}
        />
      </div>

      <div className="flex items-start gap-4">
        <InputField
          {...register(
            `verraQuestionnaire.${yearKey}.fertilisers.${index}.organicCarbonPercentage`,
            {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            },
          )}
          step=".01"
          type="number"
          label={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertilisers.carbon_percent",
          )}
          rightAddon="%"
          placeholder={I18n.t("js.shared.enter")}
          tooltip={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertilisers.organic_tip",
          )}
          data-test={`organic-carbon-percentage-${index}`}
          error={errors?.organicCarbonPercentage}
          isDisabled={isDisabled}
          isRequired
          onWheel={e => e.currentTarget.blur()}
        />
        <InputField
          {...register(
            `verraQuestionnaire.${yearKey}.fertilisers.${index}.organicNitrogenPercentage`,
            {
              setValueAs: setInputValueAsNumber,
            },
          )}
          type="number"
          step=".01"
          label={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertilisers.nitrogen_percent",
          )}
          rightAddon="%"
          placeholder={I18n.t("js.shared.enter")}
          tooltip={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertilisers.organic_tip",
          )}
          data-test={`organic-nitrogen-percentage-${index}`}
          error={errors?.organicNitrogenPercentage}
          isDisabled={isDisabled}
          isRequired
          onWheel={e => e.currentTarget.blur()}
        />
        <InputField
          {...register(
            `verraQuestionnaire.${yearKey}.fertilisers.${index}.organicDryMatterPercentage`,
            {
              setValueAs: setInputValueAsNumber,
            },
          )}
          type="number"
          step=".01"
          label={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertilisers.dry_matter_percent",
          )}
          rightAddon="%"
          placeholder={I18n.t("js.shared.enter")}
          tooltip={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertilisers.organic_tip",
          )}
          data-test={`organic-dry-matter-percentage-${index}`}
          error={errors?.organicDryMatterPercentage}
          isDisabled={isDisabled}
          isRequired
          onWheel={e => e.currentTarget.blur()}
        />
      </div>
    </>
  );
};

export default OrganicFertiliserFormQuestions;
