import styled from "@emotion/styled";

import { ActionButton } from "@ag/components/buttons";
import { H5Typography } from "@ag/ui";

export const STranslatorWarning = styled.div`
  ${H5Typography};

  justify-content: center;
  align-items: center;
  text-align: center;

  > div {
    margin-top: 10px;
  }

  a {
    text-decoration: underline;
  }
`;

export const SButton = styled(ActionButton)`
  margin-top: 20px;
`;
