import { Spinner } from "@ag/components/Spinner";

import { SFullPageSpinner } from "./styled";

const FullPageSpinner = () => (
  <SFullPageSpinner>
    <Spinner />
  </SFullPageSpinner>
);

export default FullPageSpinner;
