import styled from "@emotion/styled";
import { ToastContainer } from "react-toastify";

import { style } from "@ag/ui";

// TODO: Rewrite to .css.ts
export const StyledToastContainer = styled(ToastContainer)`
  font-size: 14px; // set here as it's 3rd party library

  // Success notification
  .Toastify__toast--success svg {
    fill: ${style.color.green[500]};
  }

  .Toastify__progress-bar--success {
    background-color: ${style.color.green[500]};
  }

  // Error notification
  .Toastify__toast--error svg {
    fill: ${style.color.red[600]};
  }

  .Toastify__progress-bar--error {
    background-color: ${style.color.red[600]};
  }

  // Info notification
  .Toastify__toast--info svg {
    fill: ${style.color.blue[600]};
  }

  .Toastify__progress-bar--info {
    background-color: ${style.color.blue[600]};
  }
`;
