import I18n from "@ag/i18n";
import { getDecimalNumbers } from "@ag/utils/helpers";

import {
  CropProtectionAttribute,
  CropProtectionDetailsAttribute,
  CropProtectionDetailsData,
  CropProtectionV2022Data,
} from "./types";

type ValidationErrors = {
  [CropProtectionAttribute.CropProtections]: Record<string, string>[];
};

const MAX_CROP_PROTECTION_APPLICATION = 50;

const getErrorTranslation = (attribute: CropProtectionDetailsAttribute) => {
  const lookup: Record<CropProtectionDetailsAttribute, string> = {
    [CropProtectionDetailsAttribute.Type]: I18n.t(
      "js.carbon.enter_protectionType_value",
    ),
    [CropProtectionDetailsAttribute.ApplicationRate]: I18n.t(
      "js.carbon.enter_applicationRate_value",
    ),
    [CropProtectionDetailsAttribute.Category]: I18n.t(
      "js.carbon.enter_category_value",
    ),
  };

  return lookup[attribute];
};

export const validate = (values: CropProtectionV2022Data) => {
  const errors: ValidationErrors = {
    [CropProtectionAttribute.CropProtections]: [],
  };

  const attributes = Object.values(CropProtectionDetailsAttribute);

  if (!values) return errors;

  /**
   * Iterate thru number of applications to validate all of them
   */
  values[CropProtectionAttribute.CropProtections]?.forEach(
    (cropProtectionDetails: CropProtectionDetailsData, index: number) => {
      attributes.forEach(attribute => {
        const attributeValue =
          cropProtectionDetails && cropProtectionDetails[attribute];

        const isFieldTypeDisabled =
          attribute === CropProtectionDetailsAttribute.ApplicationRate &&
          !cropProtectionDetails[CropProtectionDetailsAttribute.Type];

        if (!attributeValue && !isFieldTypeDisabled) {
          errors[CropProtectionAttribute.CropProtections][index] = {
            ...errors[CropProtectionAttribute.CropProtections][index],
            [attribute]: getErrorTranslation(attribute),
          };
        }

        if (attribute === CropProtectionDetailsAttribute.ApplicationRate) {
          if (Number(attributeValue) === 0) {
            errors[CropProtectionAttribute.CropProtections][index] = {
              ...errors[CropProtectionAttribute.CropProtections][index],
              [attribute]: I18n.t(`js.shared.value_must_be_grater_than_zero`),
            };
          }

          if (Number(attributeValue) > MAX_CROP_PROTECTION_APPLICATION) {
            errors[CropProtectionAttribute.CropProtections][index] = {
              ...errors[CropProtectionAttribute.CropProtections][index],
              [attribute]: I18n.t(
                `js.carbon.field.crop_protection_max_application_error`,
              ),
            };
          }

          const decimalNumbers = getDecimalNumbers(Number(attributeValue));

          // max 2 decimal values are allowed
          if (decimalNumbers && decimalNumbers.length > 2) {
            errors[CropProtectionAttribute.CropProtections][index] = {
              ...errors[CropProtectionAttribute.CropProtections][index],
              [attribute]: I18n.t("js.shared.form.errors.max_decimal_values", {
                count: 2,
              }),
            };
          }
        }
      });
    },
  );

  return errors;
};
