import styled from "@emotion/styled";

import { SFontAwesomeIcon } from "@ag/components/Icon";
import { style } from "@ag/ui";

const size = {
  successIcon: 100,
};

export const SIconWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${style.color.opal[100]};
  border-radius: 50%;
  width: ${size.successIcon}px;
  height: ${size.successIcon}px;
  margin-bottom: 24px;
`;

export const SIcon = styled(SFontAwesomeIcon)`
  font-size: 36px;
  color: ${style.color.green[400]};
`;

export const SSection = styled.section`
  text-align: center;
`;

export const SDescription = styled.p`
  margin-bottom: 24px;
`;
