import styled from "@emotion/styled";

import { H5Typography, media, style } from "@ag/ui";

const size = {
  amountColumnWidth: 170,
};

export const SFertiliserRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
  border-radius: ${style.borderRadius}px;

  &:nth-of-type(odd) {
    background: ${style.color.grey[100]};
  }
`;

export const SFertiliserName = styled.div`
  ${H5Typography};
`;

export const SInputWrapper = styled.div`
  padding-left: 4px;
  width: ${size.amountColumnWidth}px;
`;

export const SApplicationMethodWrapper = styled.div`
  padding-top: 16px;
`;

export const SChemicalSpecification = styled.div`
  margin: 0 auto;

  ${media.sm`
    width: 100%;
  `}
`;
