import { add, isAfter, sub } from "date-fns";
import { useCallback, useEffect, useState } from "react";

import { USER_AUTH_DATA_STORAGE_KEY } from "@ag/utils/constants";
import { useIsDocumentFocused } from "@ag/utils/hooks";
import { saveToLocalStorage } from "@ag/utils/storage";

import { refreshSession as refreshSessionRequest } from "../api/refresh-session";
import { useSessionContext } from "../contexts/session";

const REFRESH_TOKEN_MINUTES_BEFORE_EXPIRATION = 5;

const sessionBroadcastChannel = new BroadcastChannel("session");

export const useSessionRefresh = () => {
  const [isSessionRefreshing, setIsSessionRefreshing] = useState(true);

  const { currentUser, userAuthData, fetchCurrentUser, setUserAuthData } =
    useSessionContext();

  useEffect(() => {
    saveToLocalStorage("session-refreshing", isSessionRefreshing);
  }, [isSessionRefreshing]);

  const isDocumentFocused = useIsDocumentFocused();

  const refreshSession = useCallback(async () => {
    if (!userAuthData) return;

    setIsSessionRefreshing(true);

    try {
      const newAuthData = await refreshSessionRequest(
        userAuthData.refreshToken,
      );

      saveToLocalStorage(USER_AUTH_DATA_STORAGE_KEY, newAuthData);

      sessionBroadcastChannel.postMessage("auth-data:updated");

      if (!currentUser) {
        await fetchCurrentUser();
      }

      setUserAuthData(newAuthData);
    } catch (error) {
      console.error("Error refreshing token:", error);
    } finally {
      setIsSessionRefreshing(false);
    }
  }, [currentUser, userAuthData, fetchCurrentUser, setUserAuthData]);

  useEffect(() => {
    const fetchUser = async () => {
      await fetchCurrentUser();
      setIsSessionRefreshing(false);
    };

    if (!userAuthData) {
      setIsSessionRefreshing(false);
      return;
    }

    const isTokenExpired = isAfter(
      add(new Date(), { minutes: REFRESH_TOKEN_MINUTES_BEFORE_EXPIRATION }),
      new Date(userAuthData.expiresAt),
    );

    const shouldRefreshToken = isTokenExpired && isDocumentFocused;

    // Initial load refresh and current user fetch
    if (!currentUser) {
      if (shouldRefreshToken) {
        refreshSession();
      } else {
        fetchUser();
      }
    } else {
      setIsSessionRefreshing(false);
    }
  }, [
    currentUser,
    userAuthData,
    isDocumentFocused,
    fetchCurrentUser,
    refreshSession,
  ]);

  // Running app's token refresh
  useEffect(() => {
    let refreshTimer: ReturnType<typeof setTimeout> | null = null;

    if (userAuthData && isDocumentFocused) {
      // Calculate time to refresh
      const refreshDate = sub(new Date(userAuthData.expiresAt), {
        minutes: REFRESH_TOKEN_MINUTES_BEFORE_EXPIRATION,
      });
      const now = new Date();
      const timeToRefresh = refreshDate.getTime() - now.getTime();

      refreshTimer = setTimeout(() => {
        refreshSession();
      }, timeToRefresh);
    } else if (!isDocumentFocused) {
      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
    }

    return () => {
      if (refreshTimer) {
        clearInterval(refreshTimer);
      }
    };
  }, [userAuthData, isDocumentFocused, refreshSession]);

  return {
    isSessionRefreshing,
    refreshSession,
  };
};
