import { cva } from "class-variance-authority";
import { forwardRef } from "react";

import { Icon } from "~assets";
import { cn } from "~utils";

import { ICONS_REGULAR } from "../../assets/Icon";
import { ChipSize, ChipVariant } from "./types";

const chipVariants = cva(
  "inline-flex items-center justify-center gap-1 rounded px-2",
  {
    variants: {
      variant: {
        success: "bg-positive-background text-positive-foreground",
        warning: "bg-warning-background text-warning-foreground",
        neutral: "bg-neutral-background text-neutral-foreground",
        info: "bg-info-background text-info-foreground",
        danger: "bg-danger-background text-danger-foreground",
      },
      size: {
        small: "text-h7",
        large: "py-0.5 text-h5",
      },
      isIconOnly: {
        true: "rounded-full p-0.5",
      },
      isHoverable: {
        true: "cursor-pointer",
      },
    },
    compoundVariants: [
      {
        isHoverable: true,
        variant: "success",
        className: "hover:bg-success-hover",
      },
      {
        isHoverable: true,
        variant: "warning",
        className: "hover:bg-warning-hover",
      },
      {
        isHoverable: true,
        variant: "neutral",
        className: "hover:bg-neutral-hover",
      },
      {
        isHoverable: true,
        variant: "info",
        className: "hover:bg-info-hover",
      },
      {
        isHoverable: true,
        variant: "danger",
        className: "hover:bg-danger-hover",
      },
    ],
  },
);

const iconVariants = cva("", {
  variants: {
    variant: {
      success: "text-positive-foreground",
      warning: "text-warning-foreground",
      neutral: "text-neutral-foreground",
      info: "text-info-foreground",
      danger: "text-danger-foreground",
    },
    size: {
      small: "text-[10px]",
      large: "text-[14px]",
    },
  },
});

type BaseProps = {
  variant?: ChipVariant;
  size?: ChipSize;
  className?: string;
  onClick?: () => void;
};

type Props = BaseProps &
  (
    | React.PropsWithChildrenRequired<{
        icon?: keyof typeof ICONS_REGULAR;
      }>
    | React.PropsWithChildren<{
        icon: keyof typeof ICONS_REGULAR;
      }>
  ) &
  React.HTMLAttributes<HTMLSpanElement>;

export const Chip = forwardRef(
  (
    {
      variant = "neutral",
      size = "small",
      icon,
      children,
      className,
      onClick,
      ...rest
    }: Props,
    ref: React.Ref<HTMLSpanElement>,
  ) => {
    const isIconOnly = !children && Boolean(icon);
    const isHoverable = Boolean(onClick);

    return (
      <span
        ref={ref}
        className={cn(
          chipVariants({
            variant,
            size,
            isIconOnly,
            isHoverable,
          }),
          className,
        )}
        onClick={onClick}
        {...rest}
      >
        {icon && (
          <Icon
            variant="regular"
            name={icon}
            className={iconVariants({ variant, size })}
          />
        )}

        {children}
      </span>
    );
  },
);

Chip.displayName = "Chip";
