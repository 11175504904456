import omit from "lodash/omit";

import { Icon } from "~assets";
import { Tooltip } from "~atoms/Tooltip";

type CommonProps = React.PropsWithChildrenRequired<{
  tooltip?: string;
  onTooltipHover?: () => void;
}>;

// Declaration of common props to be omitted from the rest of the props in further parts of the code
const COMMON_PROPS = ["as", "children", "tooltip"];

export type SpanProps = CommonProps &
  React.HTMLAttributes<HTMLSpanElement> & {
    as?: "span";
  };

export type LabelProps = CommonProps &
  React.LabelHTMLAttributes<HTMLLabelElement> & {
    as?: "label";
  };

type Props = SpanProps | LabelProps;

const isLabelElement = (props: Props): props is LabelProps => {
  return props.as === "label";
};

export const Label = ({ tooltip, onTooltipHover, ...props }: Props) => {
  if (isLabelElement(props)) {
    return (
      <label
        className="inline-flex items-center text-h5 font-p1 text-grey-800"
        {...omit(props, COMMON_PROPS)}
      >
        {props.children}
        {tooltip && (
          <Tooltip content={tooltip}>
            <Icon name="circle-info" className="ml-1 p-1 text-h5 font-p1" />
          </Tooltip>
        )}
      </label>
    );
  }

  return (
    <span
      className="inline-flex items-center text-h5 font-p1 text-grey-800"
      {...omit(props, COMMON_PROPS)}
    >
      {props.children}
      {tooltip && (
        <Tooltip content={tooltip} onHover={onTooltipHover}>
          <Icon name="circle-info" className="ml-1 p-1 text-h5 font-p1" />
        </Tooltip>
      )}
    </span>
  );
};

Label.defaultProps = {
  as: "span",
};
