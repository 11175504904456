import isEmpty from "lodash/isEmpty";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";

import { Icon } from "~assets";
import { cn } from "~utils";

export type Props = React.PropsWithChildrenRequired<{
  dropText: string;
  maxSize?: number;
  isDisabled?: boolean;
  acceptType?: ".zip" | ".csv" | ".pdf";
}> &
  (
    | {
        isMultiple?: false;
        value: File | undefined;
        onChange: (
          acceptedFile: File | undefined,
          fileRejection: FileRejection | undefined,
        ) => void;
      }
    | {
        isMultiple: true;
        value: File[] | undefined;
        onChange: (
          acceptedFiles: File[] | undefined,
          fileRejections: FileRejection[] | undefined,
        ) => void;
      }
  ) &
  DropzoneOptions;

export const Dropzone = ({
  value,
  children,
  dropText,
  maxSize,
  isDisabled,
  isMultiple,
  onChange,
  acceptType,
  ...rest
}: Props) => {
  const handleFileDropped = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (isMultiple) {
      const updatedFiles = [...(value ?? []), ...(acceptedFiles ?? [])];

      onChange(
        isEmpty(updatedFiles) ? undefined : updatedFiles,
        isEmpty(fileRejections) ? undefined : fileRejections,
      );
    } else {
      onChange(
        isEmpty(acceptedFiles) ? undefined : acceptedFiles[0],
        isEmpty(fileRejections) ? undefined : fileRejections[0],
      );
    }
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: acceptType
      ? {
          ...(acceptType === ".zip" && { "application/*": [".zip"] }),
          ...(acceptType === ".csv" && { "text/*": [".csv"] }),
          ...(acceptType === ".pdf" && { "application/pdf": [".pdf"] }),
        }
      : undefined,
    ...rest,
    // Maximum file size (in bytes)
    maxSize: maxSize ? maxSize * 1000000 : undefined,
    multiple: isMultiple,
    noClick: isDisabled,
    noDrag: isDisabled,
    onDrop: handleFileDropped,
  });

  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center rounded border border-dashed border-green-500 p-4",
        {
          "border-blue-500 bg-blue-100": isDragActive,
          "bg-white border-green-500": !isDragActive && !isDisabled,
          "cursor-not-allowed border-grey-500 bg-grey-100": isDisabled,
          "cursor-pointer": !isDisabled,
        },
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {isDragActive ? (
        <div className="flex-column flex items-center">
          <Icon
            name="file-arrow-up"
            className="rounded bg-white-100 p-3 text-h4"
          />

          <h5 className="text-h5">{dropText}</h5>
        </div>
      ) : (
        children
      )}
    </div>
  );
};
