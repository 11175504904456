import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField, InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";

import { isDisabledAtom } from "../../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../../schemas/field-definition-form";
import { FuelType } from "../../types/field-definition-data";
import AdditionalInformationTip from "../additional-information-tip";
import { YearKey } from "./crop-form-section";

const FuelUsageFormSection = ({ yearKey }: { yearKey: YearKey }) => {
  const [isDisabled] = useAtom(isDisabledAtom);
  const { control, register, formState, watch } =
    useFormContext<FieldDefinitionData>();

  const fuelType = watch(`verraQuestionnaire.${yearKey}.fuelType`);
  const errors = formState.errors.verraQuestionnaire?.[yearKey];

  return (
    <FormSection.Root
      title={I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.fuel.title",
      )}
    >
      <FormSection.Card>
        <Controller
          name={`verraQuestionnaire.${yearKey}.fuelType`}
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.fuel_type.type",
              )}
              tooltip={I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.fuel_type.type_tip",
              )}
              testId="fuel-type"
              error={errors?.fuelType}
              isDisabled={isDisabled}
              isRequired
            >
              <ButtonSelect.Option value={FuelType.DIESEL}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.fuel_type.diesel",
                )}
              </ButtonSelect.Option>

              <ButtonSelect.Option value={FuelType.GASOLINE}>
                {I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.fuel_type.gas_petrol",
                )}
              </ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />

        {Boolean(fuelType) && (
          <>
            <AdditionalInformationTip />

            <div style={{ width: "100%", maxWidth: 221 }}>
              <InputField
                {...register(`verraQuestionnaire.${yearKey}.fuelAmount`, {
                  setValueAs: v => (v === "" ? undefined : parseFloat(v)),
                })}
                step=".01"
                label={I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.fuel_type.amount",
                  {
                    type:
                      fuelType === FuelType.DIESEL
                        ? I18n.t(
                            "js.carbon.field_details.field_definition.questionnaire.fuel_type.diesel",
                          )
                        : I18n.t(
                            "js.carbon.field_details.field_definition.questionnaire.fuel_type.gas_petrol",
                          ),
                  },
                )}
                tooltip={I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.fuel_type.amount_tip",
                )}
                rightAddon={I18n.t(
                  "models.concerns.carbon_fields.energy_consumption_concern.energy_consumption_amount_units.l",
                )}
                placeholder={I18n.t("js.shared.enter")}
                error={errors?.fuelAmount}
                data-test="fuel-amount"
                isDisabled={isDisabled}
                onWheel={e => e.currentTarget.blur()}
              />
            </div>
          </>
        )}
      </FormSection.Card>
    </FormSection.Root>
  );
};

export default FuelUsageFormSection;
