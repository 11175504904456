import styled from "@emotion/styled";

import { ActionButton } from "@ag/components/buttons";
import { H5Typography, media, style } from "@ag/ui";

type SListTitleProps = {
  isSkeleton?: boolean;
};

type SRemoveAllButtonProps = {
  disabled: boolean;
};

export const SListTitleWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;

  background-color: ${style.color.white[100]};
`;

export const SListTitle = styled.div`
  ${H5Typography};

  background-color: ${style.color.grey[200]};
  line-height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 16px;

  margin-top: ${({ isSkeleton }: SListTitleProps) => (isSkeleton ? "16px" : 0)};

  ${media.sm`
    font-size: 12px;
  `};
`;

export const SMarkFieldIcon = styled.img`
  margin-left: 12px;
  width: 24px;
  cursor: pointer;
`;

export const SFieldsForValidationModalListContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SRemoveAllButton = styled(ActionButton)`
  /**
  TODO remove when disabled text button styling is available globally
  https://app.asana.com/0/1120148049704812/1200030284508444
  */
  ${({ disabled }: SRemoveAllButtonProps): string => {
    let styles = ``;

    if (disabled) {
      styles += `
        &&& {
          cursor: initial;
          pointer-events: none;
          color: ${style.color.grey[700]};
        }
      `;
    }

    return styles;
  }}
`;

export const SAdditionalActions = styled.div`
  width: 100%;
  text-align: right;
  line-height: 24px;
`;
