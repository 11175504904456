import { GoogleMap, Marker } from "@react-google-maps/api";
import { useCallback, useRef } from "react";

import { FieldPolygon } from "@ag/map/components";
import { getPolygonBounds } from "@ag/map/helpers";
import { GeoJSONGeometry, LatLng } from "@ag/map/types";

import { ModalContentProps } from "~components/GlobalModal";
import { DEFAULT_ZOOM_LEVEL } from "~lib/google-maps";

import { MAP_OPTIONS } from "./constants";
import { SFieldMap } from "./styled";

export type Extras = {
  position?: LatLng;
  boundaries?: GeoJSONGeometry | null;
  fieldId?: string;
};

const FieldMap = ({ extras }: ModalContentProps) => {
  const { position, boundaries } = (extras as Extras) || {};

  const coordinates = boundaries?.coordinates;

  const mapInstanceRef = useRef<google.maps.Map | null>(null);

  const handleMapLoaded = useCallback((map: google.maps.Map) => {
    mapInstanceRef.current = map;
  }, []);

  const handlePolygonLoad = (polygon?: google.maps.Polygon) => {
    // TODO: Remove this check when MultiPolygon is supported
    if (boundaries?.type === "MultiPolygon" || !polygon) return;

    // TODO: Replace with centerMap when we got bbox from the backend for the field instead of geometry only
    const bounds = getPolygonBounds(polygon);
    mapInstanceRef.current?.fitBounds(bounds);
  };

  // TODO: Uncomment when app is connected to new boundary editing flow
  // const handleEditButtonClick = () => {
  //   closeModal();

  //   navigate(`/carbon/farmer/field/${fieldId}/edit-boundaries`, {
  //     state: { from: `${window.location.pathname}${window.location.search}` },
  //   });
  // };

  return (
    <SFieldMap>
      <GoogleMap
        mapContainerClassName="field-map"
        center={position}
        options={MAP_OPTIONS}
        zoom={DEFAULT_ZOOM_LEVEL}
        onLoad={handleMapLoaded}
      >
        {coordinates && (
          <FieldPolygon
            field={{ geometry: boundaries }}
            onLoad={handlePolygonLoad}
          />
        )}

        {position && !coordinates && <Marker position={position} />}
      </GoogleMap>

      {/* TODO Uncomment when needed */}
      {/* {fieldId && !isEmpty(coordinates) && <ReportFooter fieldId={fieldId} />} */}

      {/* TODO: Uncomment when app is connected to new boundary editing flow */}
      {/* {coordinates && (
        <EditInfoFooter onEditButtonClick={handleEditButtonClick} />
      )} */}
    </SFieldMap>
  );
};

export default FieldMap;
