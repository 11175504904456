import { faBomb } from "@fortawesome/pro-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";

import { SFontAwesomeIcon } from "@ag/components/Icon";
import { SModalFooter, SModalTitle } from "@ag/components/Modal";
import I18n from "@ag/i18n";

import { ModalContentProps } from "~components/GlobalModal";

import { SButton, STranslatorWarning } from "./styled";

const TranslatorUsageWarning = ({ onClose }: ModalContentProps) => {
  const { pathname } = useLocation();
  const isRegistrationModule =
    pathname.includes("registration") || pathname.includes("login");

  return (
    <STranslatorWarning>
      <SModalTitle>
        <SFontAwesomeIcon margin-right={4} size="2x" icon={faBomb} />
        {I18n.t("js.sessions.new.oooops")}
      </SModalTitle>

      <SModalFooter>
        {I18n.t("js.shared.extensions.translator_warning")}
      </SModalFooter>

      <div>
        {isRegistrationModule ? (
          I18n.t("js.shared.extensions.please_choose_language")
        ) : (
          <Link to="/account" onClick={onClose}>
            {I18n.t("js.shared.please_change_your_language")}
          </Link>
        )}
      </div>

      <SModalFooter justifyContent="center">
        <SButton onClick={onClose}>{I18n.t("js.shared.ok")}</SButton>
      </SModalFooter>
    </STranslatorWarning>
  );
};

export default TranslatorUsageWarning;
