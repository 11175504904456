import { useEffect, useState } from "react";

import { Feature } from "@ag/utils/types";

import { useSessionContext } from "~features/authentication";
import { useFeaturesQuery } from "~queries/features";

export const useHasFeatureAccess = (feature: Feature): boolean | undefined => {
  const { currentUser } = useSessionContext();
  const { data: features } = useFeaturesQuery(currentUser);

  const [hasFeatureAccess, setHasFeatureAccess] = useState<boolean | undefined>(
    features?.includes(feature),
  );

  useEffect(() => {
    const hasFeatureAccess = features?.includes(feature);

    setHasFeatureAccess(hasFeatureAccess);
  }, [feature, features]);

  return hasFeatureAccess;
};
