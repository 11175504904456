import { useAtom } from "jotai";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "@ag/design-system/atoms";
import { stack } from "@ag/design-system/utils";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";
import { useSessionContext } from "~features/authentication";

import { useVerraFertilisersQuery } from "../../api/get-fertilisers";
import { isDisabledAtom } from "../../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../../schemas/field-definition-form";
import { YearKey } from "../crop-form-section/crop-form-section";
import FertiliserFormQuestions from "./fertiliser-form-questions";

const FertiliserFormSection = ({ yearKey }: { yearKey: YearKey }) => {
  const [isDisabled] = useAtom(isDisabledAtom);

  const { watch, setValue } = useFormContext<FieldDefinitionData>();

  const { currentUser } = useSessionContext();

  const fertilisers = watch(`verraQuestionnaire.${yearKey}.fertilisers`);

  useEffect(() => {
    if (!fertilisers?.length) {
      // @ts-ignore {} hack to get around no fertilisers being added
      setValue(`verraQuestionnaire.${yearKey}.fertilisers`, [{}]);
    }
  }, [fertilisers, setValue, yearKey]);

  const handleAddFertiliser = () => {
    setValue(
      `verraQuestionnaire.${yearKey}.fertilisers`,
      // @ts-ignore {} hack to get around new fertilisers being added
      fertilisers ? [...fertilisers, {}] : [{}],
    );
  };

  // TODO: Replace with `~features/fertilisers` when API migrated to new fertilisers endpoint
  const organicFertiliserTypes = useVerraFertilisersQuery(currentUser!.locale);

  return organicFertiliserTypes.data ? (
    <div className={stack({ align: "end" })}>
      <div style={{ width: "100%", marginTop: 32 }}>
        <FormSection.Root title={I18n.t("js.carbon.fertiliser_title")}>
          <>
            {fertilisers?.map((_, index) => (
              <FertiliserFormQuestions
                yearKey={yearKey}
                key={`fertiliser-${yearKey}-${index}`}
                index={index}
                fertiliser={fertilisers[index]}
                organicFertiliserTypes={organicFertiliserTypes.data}
              />
            ))}
          </>
        </FormSection.Root>
      </div>
      <Button
        variant="secondary"
        icon="plus"
        onClick={handleAddFertiliser}
        data-test="add-fertiliser-button"
        disabled={isDisabled}
        type="button"
      >
        {I18n.t(
          "js.carbon.field_details.field_definition.questionnaire.fertilisers.add_fertiliser",
        )}
      </Button>
    </div>
  ) : null;
};

export default FertiliserFormSection;
