import { extendTailwindMerge } from "tailwind-merge";

import { tailwindConfig } from "./tailwind.config";

/**
 * Cause our design system uses custom classes that follow the Tailwind CSS naming we need to extend the tailwind-merge plugin to support them.
 * https://github.com/dcastil/tailwind-merge/blob/v2.2.1/docs/limitations.md
 */

export const extendedTWMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      z: Object.keys(tailwindConfig.theme.extend.zIndex).map(key => `z-${key}`),
      "font-size": Object.keys(tailwindConfig.theme.fontSize).map(
        key => `text-${key}`,
      ),
      leading: Object.keys(tailwindConfig.theme.lineHeight).map(
        key => `leading-${key}`,
      ),
      "font-weight": Object.keys(tailwindConfig.theme.fontWeight).map(
        key => `font-${key}`,
      ),
    },
  },
});
