import { useForm } from "react-final-form";

import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";
import { FertiliserAddingMode } from "~types";

import ChemicalSpecification from "./ChemicalSpecification";
import FertiliserList from "./FertiliserList";
import { SButton } from "./styled";

type Props = {
  addingMode: FertiliserAddingMode | null;
  applicationModes: LabelValue<string>[];
  fertilisers: ISOFertiliser[];
  hasApplications: boolean;
  isReadonly: boolean | undefined;
  isSubmitting: boolean | undefined;
  onAddingModeChange: (addingMode: FertiliserAddingMode) => void;
};

const FertiliserInputs = ({
  applicationModes,
  addingMode,
  fertilisers,
  hasApplications,
  isReadonly,
  isSubmitting,
  onAddingModeChange,
}: Props) => {
  const { mutators, submit } = useForm();

  const handleAddingModeButtonClicked = (
    addingMode: FertiliserAddingMode,
  ): void => {
    mutators.setIsChemicalSpecification(
      addingMode === FertiliserAddingMode.ChemicalSpecification,
    );

    onAddingModeChange(addingMode);
  };

  if (!addingMode && !isReadonly) {
    return (
      <div>
        <SButton
          type="secondary"
          testTag="fertiliser-enterChemicalQuantitiesBtn"
          onClick={(): void =>
            handleAddingModeButtonClicked(
              FertiliserAddingMode.ChemicalSpecification,
            )
          }
        >
          {I18n.t("js.carbon.enter_chemical_specifications")}
        </SButton>

        <SButton
          type="secondary"
          testTag="fertiliser-chooseFromFertiliserListButton"
          onClick={(): void =>
            handleAddingModeButtonClicked(FertiliserAddingMode.List)
          }
        >
          {I18n.t("js.carbon.choose_from_fertiliser_list")}
        </SButton>

        <SButton
          testTag="fertiliser-notUsingFertiliser"
          type="text"
          isLoading={isSubmitting}
          onClick={submit}
        >
          {I18n.t("js.carbon.not_using_fertiliser")}
        </SButton>
      </div>
    );
  }

  if (!addingMode) {
    return null;
  }

  const inputs = {
    [FertiliserAddingMode.ChemicalSpecification]: (
      <ChemicalSpecification
        applicationModes={applicationModes}
        fertilisers={fertilisers}
        isReadonly={isReadonly}
        isSubmitting={isSubmitting}
        onAddingModeButtonClick={handleAddingModeButtonClicked}
      />
    ),
    [FertiliserAddingMode.List]: (
      <FertiliserList
        applicationModes={applicationModes}
        fertilisers={fertilisers}
        hasApplications={hasApplications}
        isReadonly={isReadonly}
        isSubmitting={isSubmitting}
        onAddingModeButtonClick={handleAddingModeButtonClicked}
      />
    ),
  };

  return inputs[addingMode];
};

export default FertiliserInputs;
