import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useField, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { SFontAwesomeIcon } from "@ag/components/Icon";
import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup } from "@ag/components/form";
import I18n from "@ag/i18n";
import { getYesNoOptions } from "@ag/utils/helpers";
import { FieldStep } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";
import { SFormSubmitButtonContainer } from "~forms/styled";

import FertiliserRound from "./FertiliserRound";
import { EMPTY_FERTILISER } from "./constants";
import { SAddMoreButton } from "./styled";
import {
  FertiliserAttribute,
  FertiliserData,
  FertiliserV2022Data,
  FertiliserViewAttribute,
} from "./types";

type Props = {
  step: FieldStep.Actuals;
  fertilisers: ISOFertiliser[] | undefined;
  isReadonly?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<FertiliserV2022Data>) => void;
};

export const FertiliserForm = ({
  step,
  fertilisers,
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<FertiliserV2022Data>();

  const {
    values: { fertilisers: fertilisersData },
    pristine,
  } = useFormState<FertiliserV2022Data>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const fertilisersInput = useField<FertiliserData[]>(
    FertiliserAttribute.Fertilisers,
  );

  const handleIsFertiliserUsedChanged = (isUsed: boolean) => {
    /**
     * Add empty fertiliser row if fertilisers are marked as being used
     * and no fertilisers were added yet
     */
    if (isUsed && fertilisersData.length === 0) {
      form.mutators.push("fertilisers", EMPTY_FERTILISER);
    }

    if (!isUsed && fertilisersData.length > 0) {
      fertilisersInput.input.onChange([]);
    }
  };

  return (
    <>
      <Field
        name={FertiliserViewAttribute.IsFertilisersUsed}
        label={I18n.t("js.carbon.field.fertilisers.apply_fertilisers")}
        component={FormButtonsGroup}
        buttons={getYesNoOptions()}
        disabled={isReadonly}
        onChangeCustom={handleIsFertiliserUsedChanged}
      />

      <FieldArray name={FertiliserAttribute.Fertilisers}>
        {props => (
          <div>
            {props.fields.map((name, index) => (
              <FertiliserRound
                key={name}
                name={name}
                index={index}
                step={step}
                fertilisers={fertilisers}
                fields={props.fields}
                props={props}
                isReadonly={Boolean(isReadonly)}
              />
            ))}

            {props.fields.value.length > 0 && (
              <SAddMoreButton
                type="text"
                disabled={isReadonly}
                onClick={() => props.fields.push({})}
              >
                <SFontAwesomeIcon icon={faPlus} marginRight={8} />
                {I18n.t("js.carbon.field.fertilisers.add_more")}
              </SAddMoreButton>
            )}

            {!isReadonly && (
              <SFormSubmitButtonContainer>
                <ActionButton
                  HTMLType="submit"
                  testTag="fertilisers-saveButton"
                  disabled={pristine}
                  isLoading={isSubmitting}
                  onClick={form.submit}
                >
                  {I18n.t("js.shared.save")}
                </ActionButton>
              </SFormSubmitButtonContainer>
            )}
          </div>
        )}
      </FieldArray>
    </>
  );
};
