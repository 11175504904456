import { faEnvelopeOpenText } from "@fortawesome/pro-regular-svg-icons";

import {
  SModalDescription,
  SModalFooter,
  SModalTitle,
} from "@ag/components/Modal";
import { ActionButton } from "@ag/components/buttons";
import { SFlexDiv } from "@ag/components/styled";
import I18n from "@ag/i18n";

import { ModalContentProps } from "~components/GlobalModal";
import { SIcon, SIconWrapper, SSection } from "~components/GlobalModal/styled";

const ResaleContractSentSuccess = ({ onClose }: ModalContentProps) => (
  <SSection>
    <SFlexDiv justifyContent="center">
      <SIconWrapper>
        <SIcon icon={faEnvelopeOpenText} />
      </SIconWrapper>
    </SFlexDiv>

    <SModalTitle>
      {I18n.t(
        "js.farmer.carbon.contracts.resale_contract_preparing_modal.title",
      )}
    </SModalTitle>

    <SModalDescription>
      {I18n.t(
        "js.farmer.carbon.contracts.resale_contract_preparing_modal.description",
      )}
    </SModalDescription>

    <SModalFooter>
      <ActionButton onClick={onClose}>
        {I18n.t(
          "js.farmer.carbon.contracts.resale_contract_preparing_modal.cta",
        )}
      </ActionButton>
    </SModalFooter>
  </SSection>
);

export default ResaleContractSentSuccess;
