import { SModalDescription, SModalFooter } from "@ag/components/Modal";
import { ActionButton } from "@ag/components/buttons";
import { SFlexDiv } from "@ag/components/styled";
import I18n from "@ag/i18n";

import { ModalContentProps } from "~components/GlobalModal";

const AddFieldsUnavailable = ({ onClose }: ModalContentProps) => (
  <SFlexDiv direction="column" alignItems="center">
    <SModalDescription>
      {I18n.t("js.carbon.add_fields_unavailable")}
    </SModalDescription>

    <SModalFooter justifyContent="center">
      <ActionButton onClick={onClose}>{I18n.t("js.shared.close")}</ActionButton>
    </SModalFooter>
  </SFlexDiv>
);

export default AddFieldsUnavailable;
