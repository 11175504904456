import { ValidationErrors } from "final-form";

import I18n from "@ag/i18n";
import { KeyMap } from "@ag/utils/types";

import { FuelConsumptionAttribute } from "./types";

type FuelConsumptionDataError = KeyMap<string>;

export function validate(values: ValidationErrors): FuelConsumptionDataError {
  const errors: FuelConsumptionDataError = {};

  if (!values) return errors;

  if (!values[FuelConsumptionAttribute.EnergySource]) {
    errors[FuelConsumptionAttribute.EnergySource] = I18n.t(
      "js.carbon.enter_energy_source",
    );
  }

  if (!values[FuelConsumptionAttribute.AmountHa]) {
    errors[FuelConsumptionAttribute.AmountHa] = I18n.t(
      "js.carbon.enter_energy_consumption_amount",
    );
  }

  if (
    values[FuelConsumptionAttribute.AmountHa] &&
    values[FuelConsumptionAttribute.AmountHa] <= 0
  ) {
    errors[FuelConsumptionAttribute.AmountHa] = I18n.t(
      "js.carbon.enter_valid_energy_consumption_amount",
    );
  }

  return errors;
}
