import { forwardRef } from "react";
import { DropzoneOptions } from "react-dropzone";
import { FieldError } from "react-hook-form";

import { Dropzone, FileRejection } from "@ag/design-system/atoms";
import { FormField } from "@ag/design-system/organisms";

type Props = {
  label: string;
  children: React.ReactNode;
  tooltip?: string;
  error?: FieldError;
  isDisabled?: boolean;
  labelClassName?: string;
} & (
  | {
      isMultiple?: false;
      value: File | undefined;
      onChange: (
        acceptedFile: File | undefined,
        fileRejection: FileRejection | undefined,
      ) => void;
    }
  | {
      isMultiple: true;
      value: File[] | undefined;
      onChange: (
        acceptedFiles: File[] | undefined,
        fileRejections: FileRejection[] | undefined,
      ) => void;
    }
) &
  DropzoneOptions;

export const DropzoneField = forwardRef<unknown, Props>(
  (
    {
      labelClassName,
      label,
      tooltip,
      children,
      error,
      isDisabled = false,
      ...rest
    },
    _ref,
  ) => {
    return (
      <FormField.Root isFullSize>
        <FormField.Label className={labelClassName} tooltip={tooltip}>
          {label}
        </FormField.Label>

        <Dropzone isDisabled={isDisabled} {...rest}>
          {children}
        </Dropzone>

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

DropzoneField.displayName = "DropzoneField";
