import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Checkbox } from "@ag/design-system/atoms";
import { FormField } from "@ag/design-system/organisms";

type Props = {
  label: string | React.ReactNode;
  type?: "hidden";
  tooltip?: string;
  error?: FieldError;
  isDisabled?: boolean;
};

export const CheckboxField = forwardRef<HTMLInputElement, Props>(
  ({ label, tooltip, type, error, isDisabled = false, ...rest }, ref) => {
    return (
      <FormField.Root type={type === "hidden" ? "hidden" : undefined}>
        <FormField.InlineLabel tooltip={tooltip}>
          <Checkbox
            {...rest}
            ref={ref}
            disabled={isDisabled}
            isInvalid={Boolean(error)}
          />
          <div style={{ flex: 1 }}>{label}</div>
        </FormField.InlineLabel>

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

CheckboxField.displayName = "CheckboxField";
