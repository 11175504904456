import isEmpty from "lodash/isEmpty";

import I18n from "@ag/i18n";

import ImgAddField from "~components/GlobalModal/modals/validation-flow/assets/add-field-icon.svg";
import ImgRemoveField from "~components/GlobalModal/modals/validation-flow/assets/remove-field-icon.svg";
import {
  SAdditionalActions,
  SMarkFieldIcon,
  SRemoveAllButton,
} from "~components/GlobalModal/modals/validation-flow/components/FieldsForValidation/styled";
import { SubmitFieldsForValidationListAction } from "~components/GlobalModal/modals/validation-flow/components/FieldsForValidation/types";
import {
  SAddress,
  SAddressLine,
  SAddressName,
  SAddresses,
  SFieldItem,
  SFields,
  SListTitle,
  SListTitleWrapper,
} from "~components/GlobalModal/modals/validation-flow/styled";
import { useFarmsQuery } from "~features/farm";
import { Field } from "~queries/carbon/fields";

import { getGroupedFields } from "./helpers";
import { FieldsGroupedByFarmIdEntries } from "./types";

type onFieldClickArgs = {
  fieldId: string;
  action: SubmitFieldsForValidationListAction;
};

type Props = {
  submittableFields: Field[];
  fieldsToBeSubmitted: Record<number, boolean>;
  onFieldClick: (args: onFieldClickArgs) => void;
  onRemoveAllValidatedFieldsClick: () => void;
};

const FieldsForValidationList = ({
  submittableFields,
  fieldsToBeSubmitted,
  onFieldClick,
  onRemoveAllValidatedFieldsClick,
}: Props) => {
  const { fieldsToValidateGrouped, fieldsNotToValidateGrouped } =
    getGroupedFields({
      fieldsToBeSubmitted,
      submittableFields,
    });

  const { data: farms } = useFarmsQuery({ limit: 100 });

  const hasFieldsToValidate = !isEmpty(fieldsToValidateGrouped);
  const hasFieldsNotToValidate = !isEmpty(fieldsNotToValidateGrouped);

  return (
    <>
      <SAdditionalActions>
        <SRemoveAllButton
          type="text"
          size="extra-small"
          disabled={!hasFieldsToValidate}
          onClick={onRemoveAllValidatedFieldsClick}
        >
          {I18n.t("js.shared.remove_all").toUpperCase()}
        </SRemoveAllButton>
      </SAdditionalActions>

      {hasFieldsToValidate && (
        <SListTitleWrapper>
          <SListTitle>
            {I18n.t("js.carbon.fields_that_will_be_submitted_for_validation")}:
          </SListTitle>
        </SListTitleWrapper>
      )}

      {fieldsToValidateGrouped &&
        Object.entries(fieldsToValidateGrouped).map(
          ([id, farmFields]: FieldsGroupedByFarmIdEntries) => {
            const farm = farms?.items.find(farm => farm.id === id);

            return (
              <SAddresses key={id}>
                <SAddress>
                  <SAddressName> {farm?.name}</SAddressName>

                  <SAddressLine />
                </SAddress>

                <SFields>
                  {farmFields.map(field => (
                    <SFieldItem
                      key={field.id}
                      isActive
                      onClick={() =>
                        onFieldClick({
                          fieldId: field.id,
                          action: SubmitFieldsForValidationListAction.Remove,
                        })
                      }
                    >
                      {field.name}
                      <SMarkFieldIcon src={ImgRemoveField} />
                    </SFieldItem>
                  ))}
                </SFields>
              </SAddresses>
            );
          },
        )}

      {hasFieldsNotToValidate && (
        <SListTitleWrapper>
          <SListTitle>
            {I18n.t("js.carbon.fields_i_dont_want_to_validate")}:
          </SListTitle>
        </SListTitleWrapper>
      )}
      {fieldsNotToValidateGrouped &&
        Object.entries(fieldsNotToValidateGrouped).map(
          ([id, farmFields]: FieldsGroupedByFarmIdEntries) => {
            const farm = farms?.items.find(farm => farm.id === id);

            return (
              <SAddresses key={id}>
                <SAddress>
                  <SAddressName> {farm?.name}</SAddressName>

                  <SAddressLine />
                </SAddress>

                <SFields>
                  {farmFields.map(field => (
                    <SFieldItem
                      key={field.id}
                      isDeactivated
                      onClick={() =>
                        onFieldClick({
                          fieldId: field.id,
                          action: SubmitFieldsForValidationListAction.Add,
                        })
                      }
                    >
                      {field.name}
                      <SMarkFieldIcon src={ImgAddField} />
                    </SFieldItem>
                  ))}
                </SFields>
              </SAddresses>
            );
          },
        )}
    </>
  );
};

export default FieldsForValidationList;
