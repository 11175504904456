import styled from "@emotion/styled";

import { CollapseBox } from "@ag/components/CollapseBox";
import { style } from "@ag/ui";

export const SList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SCollapseBox = styled(CollapseBox)`
  background-color: ${style.color.blue[100]};
  margin-bottom: 24px;
  color: ${style.color.blue[900]};
`;
