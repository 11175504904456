export enum UserRole {
  Advisor = "farmer-advisor",
  Farmer = "farmer",
  InsightsCustomer = "sc_insights_customer",
}

export enum UserType {
  Advisor = "farmer-advisor",
  Broker = "broker",
  CarbonCertificateCustomer = "carbon_certificate_customer",
  CarbonCertificateBroker = "carbon_certificate_broker",
  Farmer = "farmer",
  TradingHouse = "trading_house",
}

export enum LegacyUserType {
  Broker = "broker",
  CarbonCertificateCustomer = "carbon_certificate_customer",
  CarbonCertificateBroker = "carbon_certificate_broker",
  Feedmill = "feedmill",
  Maltery = "maltery",
  Mill = "mill",
  Oilmill = "oilmill",
  TradingHouse = "trading_house",
}

const legacyUserRole = { ...LegacyUserType, ...UserRole };

export type LegacyUserRole = typeof legacyUserRole;

export enum UserValidationState {
  NotValidated = "not_validated",
  Validated = "validated",
  Waiting = "waiting",
  Revalidate = "revalidate",
}
