import isNumber from "lodash/isNumber";
import omit from "lodash/omit";
import { createRef, forwardRef, useEffect } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn } from "~utils";

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  characterCount?: number;
  isInvalid?: boolean;
};

export const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const textareaRef = createRef<HTMLTextAreaElement>();

  useEffect(() => {
    textareaRef.current?.setCustomValidity(props.isInvalid ? "error" : "");
  }, [textareaRef, props.isInvalid]);

  return (
    <div className="grid w-full grid-cols-1 grid-rows-1 place-items-end text-h5 text-grey-900">
      {props.maxLength && isNumber(props.characterCount) ? (
        <span className="col-[-1/1] row-[-1/1] m-2 text-h6 text-grey-800">
          {props.characterCount} / {props.maxLength}
        </span>
      ) : null}

      <textarea
        ref={mergeRefs([ref, textareaRef])}
        {...omit(props, ["isInvalid", "characterCount"])}
        className={cn(
          "col-[-1/1] row-[-1/1] w-full rounded border border-solid border-grey-700 bg-transparent px-4 py-[10px] text-grey-900",
          "placeholder:text-grey-700",
          "hover:border-green-400",
          "invalid:border-red-700 invalid:hover:border-red-700",
          "disabled:border-grey-200 disabled:bg-grey-200",
        )}
      />
    </div>
  );
});

Textarea.displayName = "Textarea";
