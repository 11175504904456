import { useAtom } from "jotai";
import { useMemo } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";

import { ButtonSelect, Select } from "@ag/design-system/molecules";
import { stack } from "@ag/design-system/utils";
import {
  ButtonSelectField,
  InputField,
  SelectField,
  ToggleField,
  setInputValueAsNumber,
} from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";

import { isDisabledAtom } from "../../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../../schemas/field-definition-form";
import { NameIdentifier } from "../../schemas/name-identifier";
import { ExemptionReason, Tilling } from "../../types/field-definition-data";
import FertiliserFormSection from "../fertiliser-form-section";
import CoverCropsFormSection from "./cover-crops-form-section";
import FuelUsageFormSection from "./fuel-usage-form-section";

export enum YearKey {
  ONE_YEAR = "fieldPracticesOneYearAgo",
  TWO_YEARS = "fieldPracticesTwoYearsAgo",
  THREE_YEARS = "fieldPracticesThreeYearsAgo",
  FOUR_YEARS = "fieldPracticesFourYearsAgo",
  FIVE_YEARS = "fieldPracticesFiveYearsAgo",
}

const CropFormSection = ({
  crops,
  yearKey,
}: {
  yearKey: YearKey;
  crops: NameIdentifier[];
}) => {
  const [isDisabled] = useAtom(isDisabledAtom);

  const { control, register, setValue, formState, watch } =
    useFormContext<FieldDefinitionData>();

  const data = watch(`verraQuestionnaire.${yearKey}`);

  const fieldManaged = useMemo(
    () => data?.exemptionReason !== ExemptionReason.NOT_MANAGED,
    [data?.exemptionReason],
  );

  const handleFieldManagedChange = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      `verraQuestionnaire.${yearKey}`,
      checked ? {} : { exemptionReason: ExemptionReason.NOT_MANAGED },
    );
  };
  const handleFieldUsageChange = (value: string) => {
    const isExemptionReason = Object.values(ExemptionReason).includes(
      value as ExemptionReason,
    );
    setValue(
      `verraQuestionnaire.${yearKey}.exemptionReason`,
      isExemptionReason ? (value as ExemptionReason) : undefined,
      { shouldValidate: true },
    );
    setValue(
      `verraQuestionnaire.${yearKey}.cropType`,
      isExemptionReason ? undefined : value,
      { shouldValidate: true },
    );
  };

  const errors = formState.errors.verraQuestionnaire?.[yearKey];

  return (
    <div className={stack({ gap: 32 })} style={{ marginTop: 16 }}>
      <ToggleField
        label={I18n.t(
          "js.carbon.field_details.field_definition.questionnaire.field_managed_toggle",
        )}
        checked={fieldManaged}
        data-test="field-managed-toggle"
        isDisabled={isDisabled}
        onChange={handleFieldManagedChange}
      />

      {fieldManaged && (
        <div>
          <FormSection.Root
            title={I18n.t(
              "js.carbon.field_details.field_definition.questionnaire.crop_field.title",
            )}
          >
            <FormSection.Card>
              <SelectField
                placeholder={I18n.t("js.shared.select")}
                label={I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.crop_field.title",
                )}
                error={
                  errors?.cropType || (errors?.exemptionReason as FieldError)
                }
                value={data?.exemptionReason || data?.cropType || null}
                testId="field-usage-select"
                isDisabled={isDisabled}
                isRequired
                onChange={handleFieldUsageChange}
              >
                <Select.Option value={ExemptionReason.FALLOW}>
                  {I18n.t("js.carbon.verra.exception_reason.fallow")}
                </Select.Option>

                <Select.Option value={ExemptionReason.DROUGHT}>
                  {I18n.t("js.carbon.verra.exception_reason.drought")}
                </Select.Option>

                <Select.Option value={ExemptionReason.BIODIVERSITY}>
                  {I18n.t("js.carbon.verra.exception_reason.bio-diversity")}
                </Select.Option>

                <Select.Option value={ExemptionReason.GRAZING}>
                  {I18n.t("js.carbon.verra.exception_reason.grazing")}
                </Select.Option>

                <Select.Option value={ExemptionReason.OTHER}>
                  {I18n.t("js.carbon.verra.exception_reason.other")}
                </Select.Option>

                {crops.map((crop, index) => (
                  <Select.Option value={crop.identifier} key={`crop-${index}`}>
                    {crop.name}
                  </Select.Option>
                ))}
              </SelectField>
            </FormSection.Card>
          </FormSection.Root>

          {!Object.values(ExemptionReason).includes(
            data?.exemptionReason as ExemptionReason,
          ) && (
            <>
              <FormSection.Root
                title={I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.yield.title",
                )}
              >
                <FormSection.Card>
                  <InputField
                    {...register(`verraQuestionnaire.${yearKey}.totalYield`, {
                      setValueAs: setInputValueAsNumber,
                    })}
                    label={I18n.t(
                      "js.carbon.field_details.field_definition.questionnaire.total_yield.title",
                    )}
                    type="number"
                    step=".01"
                    error={errors?.totalYield}
                    placeholder={I18n.t("js.shared.enter")}
                    rightAddon="t/ha"
                    description={I18n.t(
                      "js.carbon.field_details.field_definition.questionnaire.total_yield.hint",
                    )}
                    data-test="total-yield"
                    isDisabled={isDisabled}
                    onWheel={e => e.currentTarget.blur()}
                  />
                </FormSection.Card>
              </FormSection.Root>

              <FormSection.Root
                title={I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.tillage.title",
                )}
              >
                <FormSection.Card>
                  <Controller
                    name={`verraQuestionnaire.${yearKey}.tilling`}
                    control={control}
                    render={({ field }) => (
                      <ButtonSelectField
                        {...field}
                        testId="tilling-practice"
                        error={errors?.tilling}
                        isDisabled={isDisabled}
                        label={I18n.t(
                          "js.carbon.field_details.field_definition.questionnaire.tillage.practice",
                        )}
                        isRequired
                      >
                        <ButtonSelect.Option value={Tilling.CONVENTIONAL}>
                          {I18n.t(
                            "js.carbon.field_details.field_definition.questionnaire.tilling_practice.conventional",
                          )}
                        </ButtonSelect.Option>

                        <ButtonSelect.Option value={Tilling.MIN_TILL}>
                          {I18n.t(
                            "js.carbon.field_details.field_definition.questionnaire.tilling_practice.reduced",
                          )}
                        </ButtonSelect.Option>

                        <ButtonSelect.Option value={Tilling.NO_TILL}>
                          {I18n.t(
                            "js.carbon.field_details.field_definition.questionnaire.tilling_practice.no_till",
                          )}
                        </ButtonSelect.Option>
                      </ButtonSelectField>
                    )}
                  />
                </FormSection.Card>
              </FormSection.Root>

              <CoverCropsFormSection yearKey={yearKey} />

              <FertiliserFormSection yearKey={yearKey} />

              <FuelUsageFormSection yearKey={yearKey} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CropFormSection;
