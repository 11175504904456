import styled from "@emotion/styled";

import { ActionButton } from "@ag/components/buttons";
import { H2, P1 } from "@ag/components/styled";

const size = {
  container: {
    width: 360,
  },
  icon: {
    width: 90,
  },
};

export const SContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${size.container.width}px;
  margin: 100px auto 0 auto;
`;

export const SLogo = styled.img`
  width: 120px;
`;

export const SIcon = styled.img`
  display: inline-block;
  width: ${size.icon.width}px;
  margin: 32px 0;
`;

export const STitle = styled(H2)`
  margin-bottom: 16px;
`;

export const SMessage = styled(P1)`
  margin-bottom: 16px;
  text-align: center;

  a {
    font-weight: 700;
  }
`;

export const SButton = styled(ActionButton)`
  margin-bottom: 16px;
`;

export const SSeconds = styled.span`
  margin-left: 4px;
  font-weight: 700;
`;
